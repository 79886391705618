import React, { useState, useCallback, useRef, useEffect } from 'react';
import { ForceGraph2D } from 'react-force-graph';
import * as d3 from 'd3';
import { Paper, Group, Text, Stack, Grid, Slider } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import { IconChevronRight } from '@tabler/icons-react';
import { SkeletonLoader } from '../../core/SkeletonLoader';
import { ChannelPicker } from '../../channels/components/ChannelPicker';
import { getEnhancedChannelUMAPData } from '../../../utils/api';

// Utility function to check if the current path matches or starts with the given href
const isActive = (currentPath: string, href: string) => {
  if (href === '/channels' && currentPath.startsWith('/channels')) return true;
  if (href === '/channels/umap' && currentPath.startsWith('/channels/umap')) return true;
  return currentPath === href;
};

const navItems = [
  { title: 'Channels', href: '/channels' },
  { title: 'UMAP', href: '/channels/umap'},
  { title: 'Write Up (External)', href: '#'},
];

interface UMAPNode {
  id: string;
  x: number;
  y: number;
  topic_id: string;
  video_id: string;
  color?: string;
  fx?: number;
  fy?: number;
  title: string;
  topic_name: string;
  videoType: string;
  wikipediaTopicsPretty: string;
}

interface HoverInfo {
  title: string;
  topic_name: string;
  videoType: string;
  wikipediaTopicsPretty: string;
}

export function UMAPNetwork() {
  const [selectedChannel, setSelectedChannel] = useState<string | null>(null);
  const [graphData, setGraphData] = useState<{ nodes: UMAPNode[], links: [] }>({ nodes: [], links: [] });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [hoverInfo, setHoverInfo] = useState<HoverInfo | null>(null);
  const [zoomLevel, setZoomLevel] = useState(1);

  const fgRef = useRef<any>();
  const containerRef = useRef<HTMLDivElement>(null);

  const location = useLocation();

  useEffect(() => {
    if (selectedChannel) {
      fetchUMAPData();
    }
  }, [selectedChannel]);

  const fetchUMAPData = async () => {
    if (!selectedChannel) return;
    setLoading(true);
    setError(null);
    try {
      const response = await getEnhancedChannelUMAPData(selectedChannel);
      const umapData = response.data;
      const uniqueTopics = Array.from(new Set(umapData.map((item: any) => item.topic_id)));
      const colorScale = d3.scaleSequential(d3.interpolateTurbo)
        .domain([0, uniqueTopics.length - 1]);

      // Normalize UMAP coordinates
      const xExtent = d3.extent(umapData, (d: any) => +d.umap_x);
      const yExtent = d3.extent(umapData, (d: any) => +d.umap_y);

      const xScale = d3.scaleLinear()
        .domain(xExtent[0] !== undefined && xExtent[1] !== undefined ? [+xExtent[0], +xExtent[1]] : [0, 1])
        .range([0, 1000]);
      const yScale = d3.scaleLinear()
        .domain(yExtent[0] !== undefined && yExtent[1] !== undefined ? [+yExtent[0], +yExtent[1]] : [0, 1])
        .range([0, 1000]);

        const nodes = umapData.map((item: any) => ({
          id: item.video_id,
          x: xScale(item.umap_x),
          y: yScale(item.umap_y),
          fx: xScale(item.umap_x),
          fy: yScale(item.umap_y),
          topic_id: item.topic_id,
          video_id: item.video_id,
          color: colorScale(uniqueTopics.indexOf(item.topic_id)),
          title: item.title,
          topic_name: item.topic_name,
          videoType: item.videoType,
          wikipediaTopicsPretty: item.wikipediaTopicsPretty
        }));

      setGraphData({ nodes, links: [] });
    } catch (err) {
      console.error("Error fetching UMAP data:", err);
      setError("Failed to fetch UMAP data");
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    if (fgRef.current && graphData.nodes.length > 0) {
      const fg = fgRef.current;
      
      try {
        // Disable all forces except a very weak center force
        fg.d3Force('charge', null);
        fg.d3Force('link', null);
        fg.d3Force('center', d3.forceCenter(500, 500).strength(0.05));

        // Set up zoom behavior
        fg.zoom(d3.zoom().scaleExtent([0.1, 8]).on('zoom', (event) => {
          setZoomLevel(event.transform.k);
        }));

        // Initial zoom to fit
        fg.zoomToFit(400, 0);
      } catch (error) {
        console.error("Error setting up force simulation:", error);
      }
    }
  }, [graphData]);

  const getNodeLabel = useCallback((node: UMAPNode) => {
    return `
      Video Title: ${node.title || 'N/A'}
      <br />  
      Topic Name: ${node.topic_name || 'N/A'}
      <br />
      Type: ${node.videoType || 'N/A'}
      <br />
      YouTube's Topics: ${node.wikipediaTopicsPretty || 'N/A'}
    `;
  }, []);

  return (
    <Stack gap="xs">
      <Group gap={5}>
        {navItems.map((item, index) => (
          <React.Fragment key={item.title}>
            {index > 0 && index < 2 && <IconChevronRight size={14} color='#868e96'/>}
            {index > 1 && <Text size="sm" color="dimmed">|</Text>}
            <Text
              component={Link}
              to={item.href}
              size="sm"
              fw={index >= 1 && isActive(location.pathname, item.href) ? 500 : 'normal'}
              td={index >= 1 && isActive(location.pathname, item.href) ? 'underline' : 'none'}
              c={index < 1 ? 'dimmed' : '#0982eb'}
            >
              {item.title}
            </Text>
          </React.Fragment>
        ))}
      </Group>
      <Stack gap="xs">
        <Grid gutter="xs">
          <Grid.Col span="content" style={{ display: 'flex', alignItems: 'flex-end' }}>
            <ChannelPicker value={selectedChannel} onChange={setSelectedChannel} />
          </Grid.Col>
        </Grid>
        {loading ? (
          <SkeletonLoader count={1} height={600} radius="sm" />
        ) : (
          <Paper p="xs" shadow="xs" radius="sm" style={{ backgroundColor: '#fafafa', marginTop: '10px', overflow: 'hidden' }}>
            {graphData.nodes.length > 0 ? (
              <div ref={containerRef} style={{ width: '100%', height: '600px', position: 'relative' }}>
                <ForceGraph2D
                  ref={fgRef}
                  graphData={graphData}
                  nodeLabel={getNodeLabel}
                  nodeColor={(node: UMAPNode) => node.color || '#4169E1'}
                  nodeRelSize={4 / Math.sqrt(zoomLevel)}
                  width={containerRef.current?.clientWidth}
                  height={600}
                  cooldownTime={0}
                  onEngineStop={() => fgRef.current?.zoomToFit(400)}
                />
              </div>
            ) : (
              <Text>Please select a channel to view the UMAP visualization.</Text>
            )}
          </Paper>
        )}
      </Stack>
    </Stack>
  );
}
