import React, { useState, useEffect, useCallback } from 'react';
import { Group, Select, Box, Grid, Text, Stack, Alert, Paper } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import { IconChevronRight, IconExternalLink } from '@tabler/icons-react';
import { SkeletonLoader } from '../../core/SkeletonLoader';
import { ScatterChart, XAxis, YAxis, Tooltip, Scatter, ResponsiveContainer, Cell } from 'recharts';
import { getTrendingDailyData } from '../../../utils/api';
import { formatDateUK } from '../../../utils/dateUtils';
import { CustomDatePicker } from '../components/CustomDatePicker';

const navItems = [
  { title: 'Trending', href: '/trending' },
  { title: 'Yesterday', href: '/trending/yesterday'},
  { title: 'Performance', href: '/trending/performance'},
  { title: 'Networks', href: '/trending/networks'},
  { title: 'Data', href: '/trending/data'},
  { title: 'Notes', href: 'https://brick-river-8a5.notion.site/Trending-Performance-11f6777fc95580eab953de8d8357b2e8', isExternal: true},
];

const regionOptions = [
  { value: 'US', label: '🇺🇸 United States' },
  { value: 'GB', label: '🇬🇧 United Kingdom' },
];

const yAxisOptions = [
  { value: 'views', label: 'Views' },
  { value: 'comments', label: 'Comments' },
  { value: 'conversationRate', label: 'Conversation Rate' },
];

interface PerformanceData {
  videoId: string;
  rank: number;
  title: string;
  channelName: string;
  publishDate: string;
  views: number;
  comments: number;
  conversationRate: number;
}

export function TrendingPerformance() {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedRegion, setSelectedRegion] = useState<string>('US');
  const [selectedYAxis, setSelectedYAxis] = useState<string>('views');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [performanceData, setPerformanceData] = useState<PerformanceData[]>([]);
  const location = useLocation();

  const fetchData = useCallback(async () => {
    if (!selectedDate) {
      console.log('fetchData called, but selectedDate is null');
      return;
    }
    console.log('Fetching data for date:', selectedDate.toISOString(), 'and region:', selectedRegion);
    setLoading(true);
    setError(null);
    try {
      const response = await getTrendingDailyData(selectedDate, selectedRegion);
      console.log('Data fetched successfully:', response.data.length, 'items');
      setPerformanceData(response.data);
    } catch (err) {
      console.error('Error fetching trending data:', err);
      setError('Failed to fetch trending data. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, [selectedDate, selectedRegion]);

  useEffect(() => {
    console.log('useEffect triggered. selectedDate:', selectedDate?.toISOString());
    if (selectedDate) {
      fetchData();
    }
  }, [selectedDate, fetchData]);

  const handleDateChange = useCallback((date: Date) => {
    console.log('Date changed to:', date.toISOString());
    setSelectedDate(date);
  }, []);

  const renderScatterChart = () => (
    <Box>
      <ResponsiveContainer width="100%" height={500}>
        <ScatterChart margin={{ top: 20, right: 20, bottom: 20, left: 40 }}>
          <XAxis 
            type="number" 
            dataKey="rank" 
            name="Rank" 
            domain={[1, 50]}
            ticks={[1, 10, 20, 30, 40, 50]}
            label={{ value: 'Rank', position: 'bottom' }}
          />
          <YAxis 
            type="number" 
            dataKey={selectedYAxis} 
            name={selectedYAxis.charAt(0).toUpperCase() + selectedYAxis.slice(1)} 
            tickFormatter={(value) => new Intl.NumberFormat('en-US', { notation: 'compact', compactDisplay: 'short' }).format(value)}
            label={{ value: selectedYAxis.charAt(0).toUpperCase() + selectedYAxis.slice(1), angle: -90, position: 'insideLeft', offset: -10 }}
          />
          <Tooltip 
            cursor={{ strokeDasharray: '3 3' }}
            content={({ payload, label }) => {
              if (payload && payload.length) {
                const video = payload[0].payload as PerformanceData;
                return (
                  <div style={{ backgroundColor: '#fafafa', padding: '7px', border: '1px solid #ccc' }}>
                    <p><strong>{video.title}</strong></p>
                    <p>Channel: {video.channelName}</p>
                    <p>Published: {formatDateUK(new Date(video.publishDate))}</p>
                    <p>{selectedYAxis.charAt(0).toUpperCase() + selectedYAxis.slice(1)}: {video[selectedYAxis as keyof PerformanceData].toLocaleString()}</p>
                    <p>Trending Rank: {video.rank}</p>
                  </div>
                );
              }
              return null;
            }}
          />
          <Scatter name="Videos" data={performanceData}>
            {performanceData.map((entry, index) => {
              const rank = entry.rank;
              const red = Math.round(255 * (rank - 1) / 49);
              const blue = Math.round(255 * (50 - rank) / 49);
              return (
                <Cell key={`cell-${index}`} fill={`rgb(${red},0,${blue})`} />
              );
            })}
          </Scatter>
        </ScatterChart>
      </ResponsiveContainer>
    </Box>
  );
  
  return (
    <Stack gap="xs">
      <Group gap={5}>
      {navItems.map((item, index) => (
          <React.Fragment key={item.title}>
            {index > 0 && index < 2 && <IconChevronRight size={14} color='#868e96'/>}
            {index > 1 && <Text size="sm" color="dimmed">|</Text>}
            {item.isExternal ? (
              <Text
                component="a"
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
                size="sm"
                c="#0982eb"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {item.title}
                <IconExternalLink size={14} style={{ marginLeft: 5 }} />
              </Text>
            ) : (
              <Text
                component={Link}
                to={item.href}
                size="sm"
                fw={index >= 1 && location.pathname === item.href ? 500 : 'normal'}
                td={index >= 1 && location.pathname === item.href ? 'underline' : 'none'}
                c={index < 1 ? 'dimmed' : '#0982eb'}
              >
                {item.title}
              </Text>
            )}
      </React.Fragment>
        ))}
      </Group>
      <Paper p="xs" shadow="xs" radius="sm">
      <Stack gap="xs">
          <Grid gutter="xs">
          <Grid.Col span="content">
              <CustomDatePicker onChange={handleDateChange} />
            </Grid.Col>
            <Grid.Col span="content">
              <Select
                data={regionOptions}
                value={selectedRegion}
                onChange={(value) => {
                  console.log('Region changed to:', value);
                  setSelectedRegion(value || 'US');
                }}
              />
            </Grid.Col>
            <Grid.Col span="content">
              <Select
                data={yAxisOptions}
                value={selectedYAxis}
                onChange={(value) => {
                  console.log('Y-axis changed to:', value);
                  setSelectedYAxis(value || 'views');
                }}
              />
            </Grid.Col>
          </Grid>
          {error && (
            <Alert color="red" title="Error">
              {error}
            </Alert>
          )}
          {loading ? (
            <SkeletonLoader count={1} height={500} radius="sm" />
          ) : (
            renderScatterChart()
          )}
        </Stack>
        </Paper>
    </Stack>
  );
}