import React, { useState, useCallback, useRef, useEffect } from 'react';
import { ForceGraph2D } from 'react-force-graph';
import * as d3 from 'd3';
import { Select, Paper, Group, Text, Stack, Grid, Box } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import { IconChevronRight, IconExternalLink } from '@tabler/icons-react';
import { SkeletonLoader } from '../../core/SkeletonLoader';
import { processTrendingNetworkData, TrendingNetworkNode, TrendingNetworkLink, createNodeNeighborMap, NodeNeighborMap } from '../../../utils/trendingNetworkUtils';
import { getTrendingNetwork } from '../../../utils/api';
import { format } from 'date-fns';
import { Legend } from '../components/TrendingLegend';
import { CustomDatePicker } from '../components/CustomDatePicker';

const navItems = [
  { title: 'Trending', href: '/trending' },
  { title: 'Yesterday', href: '/trending/yesterday'},
  { title: 'Performance', href: '/trending/performance'},
  { title: 'Networks', href: '/trending/networks'},
  { title: 'Data', href: '/trending/data'},
  { title: 'Notes', href: 'https://brick-river-8a5.notion.site/Trending-Networks-11f6777fc95580c6bd7dc1cf7b9ba41a', isExternal: true},
];

export function TrendingNetworks() {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedRegion, setSelectedRegion] = useState<string | null>(null);
  const [graphData, setGraphData] = useState<{ nodes: TrendingNetworkNode[], links: TrendingNetworkLink[] } | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [highlightNodes, setHighlightNodes] = useState(new Set());
  const [highlightLinks, setHighlightLinks] = useState(new Set());
  const [hoverNode, setHoverNode] = useState<TrendingNetworkNode | null>(null);
  const [neighborMap, setNeighborMap] = useState<NodeNeighborMap>({});

  const fgRef = useRef<any>();
  const containerRef = useRef<HTMLDivElement>(null);

  const location = useLocation();

  useEffect(() => {
    if (selectedDate && selectedRegion) {
      fetchData();
    }
  }, [selectedDate, selectedRegion]);

  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
  };

  const handleRegionChange = (region: string | null) => region && setSelectedRegion(region);
  
  const fetchData = useCallback(async () => {
    if (!selectedDate || !selectedRegion) {
      setError("Please select both a date and a region.");
      return;
    }
  
    setLoading(true);
    setError(null);
  
    try {
      const response = await getTrendingNetwork(selectedDate, selectedRegion);
      const processedData = processTrendingNetworkData(response.data, selectedRegion, format(selectedDate, 'yyyy-MM-dd'));
      const newNeighborMap = createNodeNeighborMap(processedData.nodes, processedData.links);
      
      setGraphData(processedData);
      setNeighborMap(newNeighborMap);
    } catch (err) {
      setError("An error occurred while processing the data. Please try again.");
      console.error("Error processing data:", err);
    } finally {
      setLoading(false);
    }
  }, [selectedDate, selectedRegion]);

  useEffect(() => {
    if (fgRef.current && graphData && graphData.nodes.length > 0 && graphData.links.length > 0) {
      const fg = fgRef.current;
      
      try {
        // Clear existing forces
        fg.d3Force('link', null);
        fg.d3Force('charge', null);
        fg.d3Force('center', null);
        fg.d3Force('x', null);
        fg.d3Force('y', null);
  
        // Set up new forces
        fg.d3Force('link', d3.forceLink(graphData.links).id((d: any) => d.id).distance(5));
        fg.d3Force('charge', d3.forceManyBody().strength(-50));
        fg.d3Force('center', d3.forceCenter());
        fg.d3Force('x', d3.forceX().strength(0.2));
        fg.d3Force('y', d3.forceY().strength(0.2));
  
        // Reheat the simulation
        fg.d3ReheatSimulation();

        // Zoom to fit after a short delay
        setTimeout(() => {
          const distRatio = 1;
          fg.zoomToFit(400, distRatio, (node: any) => true);
        }, 500);

        // Add event listener for zoom
        fg.zoom().on('zoom', () => {
          const { x, y, k } = d3.zoomTransform(fg.zoom().current);
          fg.zoom().transform(fg.zoom().current, d3.zoomIdentity.translate(x, y).scale(k));
        });
      } catch (error) {
        console.error("Error setting up force simulation:", error);
      }
    }
  }, [graphData]);

  const handleNodeHover = useCallback((node: TrendingNetworkNode | null) => {
    highlightNodes.clear();
    highlightLinks.clear();
    if (node) {
      highlightNodes.add(node);
      const nodeNeighbors = neighborMap[node.id]?.neighbors || [];
      nodeNeighbors.forEach(neighborId => {
        const neighborNode = graphData?.nodes.find(n => n.id === neighborId);
        if (neighborNode) highlightNodes.add(neighborNode);
      });
      neighborMap[node.id]?.links.forEach(link => highlightLinks.add(link));
    }

    setHoverNode(node || null);
    updateHighlight();
  }, [neighborMap, graphData]);

  const getNodeColor = useCallback((node: TrendingNetworkNode) => {
    return node.isTrending ? '#4169E1' : '#FFA500';
  }, []);

  const getNodeSize = useCallback((node: TrendingNetworkNode) => {
    return node.isTrending ? 3 : 2;
  }, []);

  const getNodeCanvasObject = useCallback((node: TrendingNetworkNode, ctx: CanvasRenderingContext2D, globalScale: number) => {
    const nodeColor = getNodeColor(node);
    const nodeSize = getNodeSize(node) * 1.5; // Adjust multiplier as needed for visibility

    ctx.beginPath();
    ctx.arc(node.x || 0, node.y || 0, nodeSize, 0, 2 * Math.PI, false);
    ctx.fillStyle = nodeColor;
    ctx.fill();
    
    // Add stroke
    ctx.strokeStyle = '#2a3345';
    ctx.lineWidth = 1.5 / globalScale;
    ctx.stroke();
  }, [getNodeColor, getNodeSize]);

  const getLinkColor = useCallback(() => '#fafafa', []);

  const getLinkWidth = useCallback((link: TrendingNetworkLink) => {
    return highlightLinks.has(link) ? 2 : 1;
  }, [highlightLinks]);

  const getNodeLabel = useCallback((node: TrendingNetworkNode) => {
    const labels = [];
    if (node.title) labels.push(`${node.title}`);
    if (node.channel_name) labels.push(`${node.channel_name}`);
    if (node.publish_date) {
      const formattedDate = format(new Date(node.publish_date.value), 'dd/MM/yyyy');
      labels.push(`Published: ${formattedDate}`);
    }
    if (node.views) labels.push(`Views: ${node.views.toLocaleString()}`);
    if (node.isTrending && node.rank) labels.push(`Trending Rank: ${node.rank}`);
    return labels.join('<br />');
  }, []);

  const handleLinkHover = useCallback((link: TrendingNetworkLink | null) => {
    highlightNodes.clear();
    highlightLinks.clear();

    if (link) {
      highlightLinks.add(link);
      highlightNodes.add(link.source);
      highlightNodes.add(link.target);
    }

    updateHighlight();
  }, []);

  const updateHighlight = () => {
    setHighlightNodes(new Set(highlightNodes));
    setHighlightLinks(new Set(highlightLinks));
  };

  const regionOptions = [
    { value: 'US', label: '🇺🇸 United States' },
    { value: 'GB', label: '🇬🇧 United Kingdom' },
  ];

  return (
    <Stack gap="xs">
      <Group gap={5}>
      {navItems.map((item, index) => (
          <React.Fragment key={item.title}>
            {index > 0 && index < 2 && <IconChevronRight size={14} color='#868e96'/>}
            {index > 1 && <Text size="sm" color="dimmed">|</Text>}
            {item.isExternal ? (
              <Text
                component="a"
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
                size="sm"
                c="#0982eb"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {item.title}
                <IconExternalLink size={14} style={{ marginLeft: 5 }} />
              </Text>
            ) : (
              <Text
                component={Link}
                to={item.href}
                size="sm"
                fw={index >= 1 && location.pathname === item.href ? 500 : 'normal'}
                td={index >= 1 && location.pathname === item.href ? 'underline' : 'none'}
                c={index < 1 ? 'dimmed' : '#0982eb'}
              >
                {item.title}
              </Text>
            )}
      </React.Fragment>
        ))}
      </Group>
        <Stack gap="xs">
          <Grid gutter="xs">
            <Grid.Col span="content" style={{ display: 'flex', alignItems: 'flex-end' }}>
              <CustomDatePicker onChange={handleDateChange} />
            </Grid.Col>
            <Grid.Col span="content" style={{ display: 'flex', alignItems: 'flex-end' }}>
              <Select
                data={regionOptions}
                value={selectedRegion}
                onChange={handleRegionChange}
                placeholder="Select a region"
              />
            </Grid.Col>
            {graphData && (
              <Grid.Col span="content" style={{ marginLeft: 'auto' }}>
                <Box
                  p="xs"
                  style={{
                    backgroundColor: 'rgba(40, 40, 40, 0.6)',
                    border: '1px solid rgba(255, 255, 255, 0.1)',
                    borderRadius: '4px' // Match this to your UI's border radius
                  }}
                >
                  <Legend />
                </Box>
              </Grid.Col>
            )}
          </Grid>
          {loading ? (
            <SkeletonLoader count={1} height={600} radius="sm" />
          ) : (
            <Paper p="xs" shadow="xs" radius="sm" style={{ backgroundColor: '#2a3345', marginTop: '10px', overflow: 'hidden' }}>
              {graphData ? (
                <div ref={containerRef} style={{ width: '100%', height: '600px', position: 'relative' }}>
                  <ForceGraph2D
                    ref={fgRef}
                    graphData={graphData}
                    nodeLabel={getNodeLabel}
                    nodeCanvasObject={getNodeCanvasObject}
                    nodeCanvasObjectMode={() => 'replace'}
                    nodeRelSize={4}
                    linkColor={getLinkColor}  
                    linkWidth={getLinkWidth}
                    linkDirectionalParticles={2}
                    linkDirectionalParticleWidth={2}
                    linkDirectionalParticleSpeed={0.005}
                    onNodeHover={handleNodeHover}
                    onLinkHover={handleLinkHover}
                    d3AlphaDecay={0.02}
                    d3VelocityDecay={0.3}
                    cooldownTime={3000}
                    width={containerRef.current?.clientWidth}
                    height={600}
                  />
                </div>
              ) : (
                <Text color="white">Please select a date and region to view the network.</Text>
              )}
            </Paper>
          )}
        </Stack>
    </Stack>
  );
}