import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine } from 'recharts';
import { Text } from '@mantine/core';
import { formatNumber, formatDate } from '../../../utils/formatter';

interface TotalChartProps {
  chartData: ChartDataPoint[];
  metric: 'views' | 'likes' | 'comments';
  publishDate: string;
  firstTrackedDate: string;
  lastTrackedDate: string;
  updatedAtDate: string;
}

interface ChartDataPoint {
  date: string;
  daysLive: number;
  preTracking: number | null;
  tracked: number | null;
}

const CustomizedAxisTick: React.FC<any> = ({ x, y, payload }) => (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)" fontSize={12}>
        {formatDate(payload.value)}
      </text>
    </g>
  );  

export const TotalChart: React.FC<TotalChartProps> = ({
    chartData,
    metric,
    publishDate,
    firstTrackedDate,
    lastTrackedDate,
    updatedAtDate,
  }) => {
    const CustomTooltip = ({ active, payload, label }: any) => {
      if (active && payload && payload.length) {
        const data = payload[0].payload;
        const value = data.tracked !== null ? data.tracked : data.preTracking;
        return (
          <div style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}>
            <Text size="sm" fw={500}>Day {data.daysLive}</Text>
            <Text size="sm" fs="italic" c="dimmed">{formatDate(data.date)}</Text>
            <Text size="sm" fw={500}>Total {metric}: {formatNumber(value as number)}</Text>
          </div>
        );
      }
      return null;
    };

    const trackingStartIndex = chartData.findIndex(item => item.tracked !== null);

  return (
    <ResponsiveContainer width="100%" height={600}>
      <AreaChart
        data={chartData}
        margin={{ top: 10, right: 30, left: 0, bottom: 60 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          type="category"
          tick={<CustomizedAxisTick />}
          domain={[publishDate, lastTrackedDate]}
          ticks={[publishDate, firstTrackedDate, lastTrackedDate]}
        />
        <YAxis tickFormatter={(value) => formatNumber(value)} />
        <Tooltip content={<CustomTooltip />} />
        <defs>
          <linearGradient id="colorPreTracking" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#808080" stopOpacity={0.2}/>
            <stop offset="95%" stopColor="#808080" stopOpacity={1}/>
          </linearGradient>
          <linearGradient id="colorTracked" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#0d3fa6" stopOpacity={1}/>
            <stop offset="95%" stopColor="#0d3fa6" stopOpacity={0.2}/>
          </linearGradient>
        </defs>
        <Area 
          type="monotone" 
          dataKey="preTracking"
          fill="url(#colorPreTracking)"
          stroke="#808080"
          strokeWidth={1}
          fillOpacity={1}
          isAnimationActive={true}
          animationDuration={2000}
          connectNulls
        />
        <Area 
          type="monotone" 
          dataKey="tracked"
          fill="url(#colorTracked)"
          stroke="#0d3fa6"
          strokeWidth={1}
          fillOpacity={1}
          isAnimationActive={true}
          animationDuration={2000}
          connectNulls
        />
        <ReferenceLine
          x={firstTrackedDate}
          stroke="gray"
          strokeDasharray="3 3"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default TotalChart;