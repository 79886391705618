import React, { useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { ReleaseNotesPage } from './New';
import { BuildNotesPage } from './BuildNotes';

export function Releases() {
  const { subpage } = useParams<{ subpage: string }>();

  switch (subpage) {
    case 'new':
       return <ReleaseNotesPage/>;
    case 'build-notes':
      return <BuildNotesPage/>;
    default: return <Navigate to="/docs/releases/new" replace />;
  }
}