import React, { ReactNode, useState } from 'react';
import { AppShell, Text, ScrollArea, Burger, Group, UnstyledButton, Avatar, Menu, useMantineTheme, Collapse } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconChevronDown, IconTrendingUp, IconTopologyStar3, IconCircleSquare, IconFlaskFilled, IconFileDescription, IconChevronRight, IconChevronLeft, IconDog } from '@tabler/icons-react';
import { Link, useLocation } from 'react-router-dom';

interface NavItem {
  icon: React.ComponentType<any>;
  label: string;
  items: {
    label: string;
    link: string;
    subItems?: { label: string; link: string }[];
  }[];
}

interface CoreLayoutProps {
  children: ReactNode;
}


const navItems: NavItem[] = [

  // Trending NavItems
  {
    icon: IconTrendingUp,
    label: 'Trending    ',
    items: [
      { 
        label: 'Yesterday',
        link: '/trending/yesterday',
      },
      { 
        label: 'Performance',
        link: '/trending/performance',
      },
      { 
        label: 'Networks',
        link: '/trending/networks',
      },
      { 
        label: 'Data',
        link: '/trending/data',
      },
    ],
  },

  // Channels NavItems
  {
    icon: IconCircleSquare,
    label: 'Channels',
    items: [
      { 
        label: 'Channel',
        link: '/channels/channel',
      },
      { 
        label: 'Top 5',
        link: '/channels/top-five',
      },
      { 
        label: 'Video',
        link: '/channels/video',
      },
      { 
        label: 'Networks',
        link: '/channels/networks',
        subItems: [
          { label: 'Network', link: '/channels/networks/network' },
          { label: 'Subgraph', link: '/channels/networks/subgraph' },
        ],
      },
      { 
        label: 'Timeline',
        link: '/channels/timeline',
      },
      { 
        label: 'Performance',
        link: '/channels/performance',
        subItems: [
          { label: '2D', link: '/channels/performance/two-d' },
          { label: '3D', link: '/channels/performance/three-d' },
          { label: 'Treemap', link: '/channels/performance/treemap' },
          { label: 'Swarm', link: '/channels/performance/swarm' },
        ],
      },
      {
        label: 'Data',
        link: '/channels/data',
      },
    ],
  },

  // Experiments NavItems
  {
    icon: IconFlaskFilled,
    label: 'Experiments',
    items: [
      {
        label: 'Model Explorer',
        link: '/experiments/model-explorer',
      },
      { 
        label: 'Source / Subsidiary',
        link: '/experiments/source-subsidiary',
      },
      { 
        label: 'Thumbnail Scores',
        link: '/experiments/thumbnail-scores',
      },
    ],
  },
  {
    icon: IconFileDescription,
    label: 'Docs',
    items: [
      { 
        label: 'New! v1.0.0',
        link: '/docs/new-one',
      },
    ],
  },
];

export function CoreLayout({ children }: { children: React.ReactNode }) {
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  const [expandedSections, setExpandedSections] = useState<string[]>([]);
  const [expandedPages, setExpandedPages] = useState<string[]>([]);
  const location = useLocation();
  const isMobile = useMediaQuery('(max-width: 768px)');

  const toggleSection = (label: string) => {
    setExpandedSections(prev =>
      prev.includes(label) ? prev.filter(item => item !== label) : [...prev, label]
    );
  };

  const togglePage = (label: string) => {
    setExpandedPages(prev =>
      prev.includes(label) ? prev.filter(item => item !== label) : [...prev, label]
    );
  };

  const NavbarContent = (
    <>
      {navItems.map((section) => (
        <div key={section.label}>
          <UnstyledButton
            onClick={() => toggleSection(section.label)}
            style={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              padding: theme.spacing.xs,
              size: "xs",
              borderRadius: theme.radius.xs,
              color: "#0982eb",
              // backgroundColor: theme.colors.gray[4],
              '&:hover': {
                backgroundColor: theme.colors.gray,
              },
            })}
          >
            <section.icon size={16} color="#0982eb"/>
            {opened && (
              <>
                <Text ml="md" fw={500}>{section.label}</Text>
                {expandedSections.includes(section.label) ? <IconChevronDown size={14} /> : <IconChevronRight size={14} />}
              </>
            )}
          </UnstyledButton>
          {opened && expandedSections.includes(section.label) && (
            <Collapse in={expandedSections.includes(section.label)}>
              {section.items.map((item) => (
                <div key={item.label}>
                  <UnstyledButton
                    component={Link}
                    to={item.link}
                    onClick={() => togglePage(item.label)}
                    ml={20}
                    style={(theme) => ({
                      // indent: "20px",
                      display: 'flex',
                      alignItems: 'center',
                      padding: theme.spacing.xs,
                      paddingLeft: theme.spacing.xs,
                      borderRadius: `0px`,
                      color: location.pathname === item.link ? "#0d3fa6" : theme.colors.text,
                      // fw: location.pathname === item.link ? 500 : 100,
                      borderLeft: `2px solid ${location.pathname === item.link ? "#0982eb" : theme.colors.gray[4]}`,
                      backgroundColor: location.pathname === item.link 
                        ? theme.colors.dark 
                          ? "#e2f1fe" 
                          : theme.colors.gray[1]
                        : 'transparent',
                      '&:hover': {
                        backgroundColor: theme.colors.gray,
                      },
                    })}
                  >
                    <Text size="sm">{item.label}</Text>
                    {item.subItems && (
                      expandedPages.includes(item.label) ? <IconChevronDown size={14} /> : <IconChevronRight size={14} />
                    )}
                  </UnstyledButton>
                  {item.subItems && expandedPages.includes(item.label) && (
                    <Collapse in={expandedPages.includes(item.label)}>
                      {item.subItems.map((subItem) => (
                        <UnstyledButton
                          key={subItem.label}
                          component={Link}
                          to={subItem.link}
                          ml={30}
                          mr={10}
                          style={(theme) => ({
                            display: 'flex',
                            alignItems: 'center',
                            padding: theme.spacing.xs,
                            paddingLeft: theme.spacing.xs,
                            borderRadius: `0px`,
                            color: location.pathname === subItem.link ? "#0d3fa6" : theme.colors.text,
                            fontWeight: location.pathname === item.link ? 500 : 100,
                            borderLeft: `2px solid ${location.pathname === subItem.link ? "#0982eb" : theme.colors.gray[4]}`,
                            backgroundColor: location.pathname === subItem.link 
                              ? theme.colors.dark 
                                ? "#e2f1fe" 
                                : theme.colors.gray[1]
                              : 'transparent',
                            '&:hover': {
                              backgroundColor: theme.colors.gray,
                            },
                          })}
                        >
                          <Text size="sm">{subItem.label}</Text>
                        </UnstyledButton>
                      ))}
                    </Collapse>
                  )}
                </div>
              ))}
            </Collapse>
          )}
        </div>
      ))}
    </>
  );

  return (
    <AppShell
      padding="md"
      navbar={{
        width: opened ? 250 : 70,
        breakpoint: 'sm',
        collapsed: { mobile: !opened, desktop: false },
      }}
      header={{ height: 60 }}
      footer={{ height: 40, collapsed: true, offset: false }}
    >
      <AppShell.Header style={{ backgroundColor: "#0d3fa6" }}>
        <Group style={{ height: '100%', justifyContent: 'space-between' }} px={20}>
          <Group>
            <Burger
              opened={opened}
              onClick={() => setOpened((o) => !o)}
              size="sm"
              color={theme.white}
            />
            <Text color={theme.white}  style={{ display: 'flex', alignItems: 'center' }}>
              sven.dog/analytics
            </Text>
          </Group>
          <Menu shadow="md" width={200}>
            <Menu.Target>
              <UnstyledButton>
                <Group gap={7}>
                  <IconDog size={20} color={theme.white} />
                  <IconChevronDown size={20} stroke={1.5} color={theme.white} />
                </Group>
              </UnstyledButton>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item component="a" href="https://forms.gle/6Nu79XK4TFpYftac6" target="_blank">
                Request Access
              </Menu.Item>
              <Menu.Item component="a" href="#" target="_blank">
                Contact
              </Menu.Item>
              <Menu.Item component="a" href="https://brick-river-8a5.notion.site/1086777fc95580abbfd1c8f85f01c198?v=1086777fc95580a1857d000cea89d780" target="_blank">
                Help
              </Menu.Item>
              <Menu.Item component={Link} to="/logout">
                Logout
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      </AppShell.Header>

      <AppShell.Navbar
        p="md"
        style={{ boxShadow: '1px 0 1px rgba(0,0,0,0.1)' }}
        onClick={() => !opened && setOpened(true)}
        >
        <ScrollArea h="100%">
          {NavbarContent}
        </ScrollArea>
      </AppShell.Navbar>


      <AppShell.Main 
        styles={{
          main: {
            backgroundColor: '#f8f9fa',
          },
        }}
      >
        {children}
      </AppShell.Main>

      <AppShell.Footer p="xs"> {/* Reduced padding */}
        <Text ta="center" size="xs" c="dimmed"> {/* Smaller text */}
          2024 last.works // sven.dog/analytics
        </Text>
      </AppShell.Footer>
    </AppShell>
  );
}