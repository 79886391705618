import React from 'react';
import { MultiSelect } from '@mantine/core';

interface EdgeTypePickerProps {
  value: string[];
  onChange: (value: string[]) => void;
}

const edgeTypes = [
  { value: 'shorts_related', label: 'Shorts Related' },
  { value: 'endscreen', label: 'Endscreen' },
  { value: 'description', label: 'Description' },
];

export function EdgeTypePicker({ value, onChange }: EdgeTypePickerProps) {
  return (
    <MultiSelect
      data={edgeTypes}
      value={value}
      onChange={onChange}
      placeholder="Select edge types"
      clearable
      searchable
    />
  );
}