import React, { useState, useCallback, useRef, useEffect } from 'react';
import { ForceGraph2D } from 'react-force-graph';
import * as d3 from 'd3';
import {  Paper, Group, Text, Stack, Grid } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import { IconChevronRight } from '@tabler/icons-react';
import { SkeletonLoader } from '../../../core/SkeletonLoader';
import { processChannelNetworkData, ChannelNetworkNode, ChannelNetworkLink, createNodeNeighborMap, NodeNeighborMap, filterGraphData } from '../../../../utils/channelNetworkUtils';
import { getChannelNetwork } from '../../../../utils/api';
import { ChannelPicker } from '../../components/ChannelPicker';
import { EdgeTypePicker } from '../../components/EdgeTypePicker'; // placeholder

// Utility functions to check if the current path matches or starts with the given href
const isActive = (currentPath: string, href: string) => {
  if (href === '/channels' && currentPath.startsWith('/channels')) return true;
  if (href === '/channels/networks' && currentPath.startsWith('/channels/networks')) return true;
  return currentPath === href;
};

const navItems = [
  { title: 'Channels', href: '/channels' },
  { title: 'Networks', href: '/channels/networks'},
  { title: 'Network', href: '/channels/networks/network'},
  { title: 'Subgraph', href: '/channels/networks/subgraph'},
  { title: 'Notes', href: 'https://brick-river-8a5.notion.site/Channels-Network-11f6777fc95580009b04e2ad5f23c0ce', isExternal: true },
];

export function ChannelNetworks() {
  const [selectedChannel, setSelectedChannel] = useState<string | null>(null);
  const [selectedEdgeTypes, setSelectedEdgeTypes] = useState<string[]>([]);
  const [graphData, setGraphData] = useState<{ nodes: ChannelNetworkNode[], links: ChannelNetworkLink[] } | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  

  const [highlightNodes, setHighlightNodes] = useState(new Set());
  const [highlightLinks, setHighlightLinks] = useState(new Set());
  const [hoverNode, setHoverNode] = useState<ChannelNetworkNode | null>(null);
  const [neighborMap, setNeighborMap] = useState<NodeNeighborMap>({});

  const fgRef = useRef<any>();
  const containerRef = useRef<HTMLDivElement>(null);

  const location = useLocation();

  useEffect(() => {
    if (selectedChannel) {
      fetchData();
    }
  }, [selectedChannel, selectedEdgeTypes]);

  // const handleChannelChange = (channel: string | null) => {
  //   setSelectedChannel(channel);
  // };
  
  const fetchData = useCallback(async () => {
    if (!selectedChannel) {
      setError("Please select a channel.");
      return;
    }
  
    setLoading(true);
    setError(null);
  
    try {
      const response = await getChannelNetwork(selectedChannel);
      console.log("API response:", response.data);
      
      const processedData = processChannelNetworkData(response.data);
      
      if (processedData.nodes.length === 0 || processedData.links.length === 0) {
        setError("No network data available for the selected channel.");
        setGraphData(null);
        return;
      }
      
      const filteredData = filterGraphData(processedData.nodes, processedData.links, selectedEdgeTypes);
      const newNeighborMap = createNodeNeighborMap(filteredData.nodes, filteredData.links);
      
      setGraphData(filteredData);
      setNeighborMap(newNeighborMap);
    } catch (err) {
      setError("An error occurred while fetching the network data. Please try again.");
      console.error("Error fetching network data:", err);
      setGraphData(null);
    } finally {
      setLoading(false);
    }
  }, [selectedChannel, selectedEdgeTypes]);

  useEffect(() => {
    if (fgRef.current && graphData && graphData.nodes.length > 0 && graphData.links.length > 0) {
      const fg = fgRef.current;
      
      try {
        // Clear existing forces
        fg.d3Force('link', null);
        fg.d3Force('charge', null);
        fg.d3Force('center', null);
        fg.d3Force('x', null);
        fg.d3Force('y', null);
  
        // Set up new forces
        fg.d3Force('link', d3.forceLink(graphData.links).id((d: any) => d.id).distance(65));
        fg.d3Force('charge', d3.forceManyBody().strength(-150));
        fg.d3Force('center', d3.forceCenter().strength(0.05));
        fg.d3Force('x', d3.forceX().strength(0.1));
        fg.d3Force('y', d3.forceY().strength(0.1));
  
        // Reheat the simulation
        fg.d3ReheatSimulation();

        // Zoom to fit after a short delay
        setTimeout(() => {
          const distRatio = 1.2;
          fg.zoomToFit(400, distRatio, (node: any) => true);
        }, 500);

        // Add event listener for zoom
        fg.zoom().on('zoom', () => {
          const { x, y, k } = d3.zoomTransform(fg.zoom().current);
          fg.zoom().transform(fg.zoom().current, d3.zoomIdentity.translate(x, y).scale(k));
        });
      } catch (error) {
        console.error("Error setting up force simulation:", error);
      }
    }
  }, [graphData]);

  const handleNodeHover = useCallback((node: ChannelNetworkNode | null) => {
    highlightNodes.clear();
    highlightLinks.clear();
    if (node) {
      highlightNodes.add(node);
      const nodeNeighbors = neighborMap[node.id]?.neighbors || [];
      nodeNeighbors.forEach(neighborId => {
        const neighborNode = graphData?.nodes.find(n => n.id === neighborId);
        if (neighborNode) highlightNodes.add(neighborNode);
      });
      neighborMap[node.id]?.links.forEach(link => highlightLinks.add(link));
    }

    setHoverNode(node || null);
    updateHighlight();
  }, [neighborMap, graphData]);

  const getNodeColor = useCallback((node: ChannelNetworkNode) => {
    // Implement color logic based on node type
    switch (node.node_type) {
      case 'X':
        return '#000000';
      case 'Shop':
        return '#007D5E';
      case 'Short':
        return '#FF0000';
      case 'Video':
        return '#FF0000';
      case 'Amazon':
        return '#FF9900';
      case 'Channel':
        return '#FF0000';
      case 'Podcast':
        return '#FF0000';
      case 'External':
        return '#0000FF';
      case 'Live':
        return '#FF0000';
      case 'TikTok':
        return '#FF0050';
      case 'Spotify':
        return '#1ED760';
      case 'Facebook':
        return '#1877F2';
      case 'Playlist':
        return '#FF0000';
      case 'Premiere':
        return '#FF0000';
      case 'Instagram':
        return '#D300C5';
      case 'Music Video':
        return '#FF0000';
      case 'Apple Podcast':
        return '#D56DFB';
      case 'YouTube (Other)':
        return '#FF0000';
      default:
        return '#0000FF';
    }
  }, []);

  const getNodeSize = useCallback((node: ChannelNetworkNode) => {
    // Implement size logic based on node type or other criteria
    return 3;
  }, []);

  const getNodeCanvasObject = useCallback((node: ChannelNetworkNode, ctx: CanvasRenderingContext2D, globalScale: number) => {
    const nodeColor = getNodeColor(node);
    const nodeSize = getNodeSize(node) * 1.5; // Adjust multiplier as needed for visibility

    ctx.beginPath();
    ctx.arc(node.x || 0, node.y || 0, nodeSize, 0, 2 * Math.PI, false);
    ctx.fillStyle = nodeColor;
    ctx.fill();
    
    // Add stroke
    ctx.strokeStyle = '#fafafa';
    ctx.lineWidth = 1.5 / globalScale;
    ctx.stroke();
  }, [getNodeColor, getNodeSize]);

  const getLinkColor = useCallback((link: ChannelNetworkLink) => {
    // Implement color logic based on edge type
    switch (link.edge_type) {
      case 'description':
        return '#D3D3D3';
      case 'endscreen':
        return '#0a326a';
      case 'shorts_related':
        return '#ada476';
      default:
        return '#D3D3D3';
    }
  }, []);

  const getLinkWidth = useCallback((link: ChannelNetworkLink) => {
    return highlightLinks.has(link) ? 2 : 1;
  }, [highlightLinks]);

  const getNodeLabel = useCallback((node: ChannelNetworkNode) => {
    const labels = [];
    if (node.title) labels.push(`${node.title}`);
    if (node.node_type) labels.push(`Type: ${node.node_type}`);
    if (node.channel_name) labels.push(`Channel: ${node.channel_name}`);
    if (node.publish_date && node.publish_date.value) {
      const date = new Date(node.publish_date.value);
      if (!isNaN(date.getTime())) {
        const formattedDate = date.toLocaleDateString();
        labels.push(`Published: ${formattedDate}`);
      }
    }
    return labels.join('<br />');
  }, []);

  const handleLinkHover = useCallback((link: ChannelNetworkLink | null) => {
    highlightNodes.clear();
    highlightLinks.clear();

    if (link) {
      highlightLinks.add(link);
      highlightNodes.add(link.source);
      highlightNodes.add(link.target);
    }

    updateHighlight();
  }, []);

  const updateHighlight = () => {
    setHighlightNodes(new Set(highlightNodes));
    setHighlightLinks(new Set(highlightLinks));
  };

  return (
    <Stack gap="xs">
      <Group gap={5}>
        {navItems.map((item, index) => (
          <React.Fragment key={item.title}>
            {index > 0 && index < 2 && <IconChevronRight size={14} color='#868e96'/>}
            {index > 1 && <Text size="sm" color="dimmed">|</Text>}
            <Text
              component={Link}
              to={item.href}
              size="sm"
              fw={index >= 1 && isActive(location.pathname, item.href) ? 500 : 'normal'}
              td={index >= 1 && isActive(location.pathname, item.href) ? 'underline' : 'none'}
              c={index < 1 ? 'dimmed' : '#0982eb'}
            >
              {item.title}
            </Text>
          </React.Fragment>
        ))}
      </Group>
        <Stack gap="xs">
          <Grid gutter="xs">
            <Grid.Col span="content" style={{ display: 'flex', alignItems: 'flex-end' }}>
              <ChannelPicker value={selectedChannel} onChange={setSelectedChannel} />
            </Grid.Col>
            <Grid.Col span="content" style={{ display: 'flex', alignItems: 'flex-end' }}>
              <EdgeTypePicker 
                value={selectedEdgeTypes} 
                onChange={(types) => {
                  setSelectedEdgeTypes(types);
                  fetchData();
                }} 
              />
            </Grid.Col>
          </Grid>
          {loading ? (
            <SkeletonLoader count={1} height={600} radius="sm" />
          ) : (
            <Paper p="xs" shadow="xs" radius="sm" style={{ backgroundColor: '#fafafa', marginTop: '10px', overflow: 'hidden' }}>
              {graphData ? (
                <div ref={containerRef} style={{ width: '100%', height: '600px', position: 'relative' }}>
                  <ForceGraph2D
                    ref={fgRef}
                    graphData={graphData}
                    nodeLabel={getNodeLabel}
                    nodeCanvasObject={getNodeCanvasObject}
                    nodeCanvasObjectMode={() => 'replace'}
                    nodeRelSize={4}
                    linkColor={getLinkColor}
                    linkWidth={getLinkWidth}
                    linkDirectionalParticles={2}
                    linkDirectionalParticleWidth={2}
                    linkDirectionalParticleSpeed={0.005}
                    onNodeHover={handleNodeHover}
                    onLinkHover={handleLinkHover}
                    d3AlphaDecay={0.02}
                    d3VelocityDecay={0.3}
                    cooldownTime={3000}
                    width={containerRef.current?.clientWidth}
                    height={600}
                  />
                </div>
              ) : (
                <Text>Please select a channel to view the network.</Text>
              )}
            </Paper>
          )}
        </Stack>
    </Stack>
  );
}