import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { formatNumber, formatDate } from '../../../utils/formatter';

interface AreaChartProps {
  data: any[];
  dataKey: string;
  color: string;
  xAxisDataKey: string;
}

const AreaChartComponent: React.FC<AreaChartProps> = ({ data, dataKey, color, xAxisDataKey }) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <AreaChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis 
          dataKey={xAxisDataKey}
          tickFormatter={(tick) => formatDate(tick)}
        />
        <YAxis tickFormatter={(tick) => formatNumber(tick)} />
        <Tooltip 
          formatter={(value: number) => formatNumber(value)}
          labelFormatter={(label) => formatDate(label as string)}
        />
        <Area
          type="monotone" 
          dataKey={dataKey} 
          stroke={color} 
          fill={color} 
          fillOpacity={0.3} 
          connectNulls
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default AreaChartComponent;