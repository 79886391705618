import React, { useState, useEffect } from 'react';
import { Paper, Group, Text, Stack, Grid, Card, Select, Checkbox, Progress, Tooltip, Divider } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import { IconChevronRight, IconExternalLink } from '@tabler/icons-react';
import { ChannelPicker } from '../components/ChannelPicker';
import { SkeletonLoader } from '../../core/SkeletonLoader';
import { getChannelSummary, getChannelChartData, getTop10Videos } from '../../../utils/api';
import { formatNumber, formatDate } from '../../../utils/formatter';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import AreaChart from '../components/AreaChart';
import StackedAreaChart from '../components/StackedAreaChart';
import SubscribersChart from '../components/ChannelSubscriberChart';
import ViewsAllChart from '../components/ChannelsViewsAllChart';
import LibrarySizeAllChart from '../components/ChannelLibrarySize';

const ChangeText: React.FC<{ change: number | null | undefined }> = ({ change }) => {
  if (change === null || change === undefined) return null;
  return (
    <Text color={change >= 0 ? 'green' : 'red'}>
      {change > 0 ? '+' : ''}{formatNumber(change)}
    </Text>
  );
};

const EXCLUDED_CHANNEL_ID = 'UC3Bg_lsqN-07NhWsyWalSOQ';

interface ChannelSummary {
  channelName: string;
  channelIconUrl: string;
  totalSubscribers: number;
  subscribersChange: number;
  totalViews: number;
  viewsChange: number;
  totalVideos: number;
  videosChange: number;
  youtubeTopics: string;
  trackedVideosCount: number;
  libraryPercentage: number;
  firstTrackedVideoDate: string | null;
  firstTrackingDate: string;
  contentTypes: string[];
  videoTypeCounts: Record<string, number>;
  firstVideoDate: string;
  mostRecentUpload: string;
  lastUpdated: string;
}

interface ChartOption {
  value: string;
  label: string;
}

// navItems for general channels section
const navItems = [
  { title: 'Channels', href: '/channels' },
  { title: 'Channel', href: '/channels/channel' },
  { title: 'Top 5', href: '/channels/top-five' },
  { title: 'Video', href: '/channels/video' },
  { title: 'Networks', href: '/channels/networks/' },
  { title: 'Timeline', href: '/channels/timeline' },
  { title: 'Performance', href: '/channels/performance' },
  { title: 'Data', href: '/channels/data' },
  { title: 'Notes', href: 'https://brick-river-8a5.notion.site/Channels-Channel-11f6777fc95580f78c07d2219639b06c', isExternal: true },
];

export function ChannelSummary() {
  const [selectedChannel, setSelectedChannel] = useState<string | null>(null);
  const [summaryData, setSummaryData] = useState<ChannelSummary | null>(null);
  const [chartData, setChartData] = useState<any[]>([]);
  const [selectedChart, setSelectedChart] = useState<string>('subscribers');
  const [trackedOnly, setTrackedOnly] = useState<boolean>(true);
  const [top10Videos, setTop10Videos] = useState<any[]>([]);
  const [top10Type, setTop10Type] = useState<'daily' | 'total'>('daily');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [excludeShorts, setExcludeShorts] = useState(false);
  const [shortsCount, setShortsCount] = useState(0);
  const [contentTypes, setContentTypes] = useState<string[]>([]);
  const [visibleContentTypes, setVisibleContentTypes] = useState<string[]>([]);
  const [visibleAreas, setVisibleAreas] = useState<string[]>([]);
  const location = useLocation();

  useEffect(() => {
    if (selectedChannel) {
      fetchSummaryData();
      fetchTop10Videos();
    }
  }, [selectedChannel]);

  useEffect(() => {
    if (selectedChannel && selectedChart) {
      fetchChartData();
    }
  }, [selectedChannel, selectedChart, trackedOnly]);

  useEffect(() => {
    if (selectedChannel) {
      fetchTop10Videos();
    }
  }, [selectedChannel, top10Type, excludeShorts]);

  useEffect(() => {
    if (summaryData) {
      setVisibleAreas(['untracked', ...summaryData.contentTypes]);
    }
  }, [summaryData]);

  const fetchSummaryData = async () => {
    if (!selectedChannel) return;
    setLoading(true);
    setError(null);
    try {
      const response = await getChannelSummary(selectedChannel);
      setSummaryData(response.data);
      setShortsCount(response.data.videoTypeCounts['Short'] || 0);
      setContentTypes(response.data.contentTypes);
      setVisibleContentTypes(response.data.contentTypes);
    } catch (err) {
      setError('Failed to fetch channel summary. Please try again.');
      console.error('Error fetching channel summary:', err);
    } finally {
      setLoading(false);
    }
  };

  const fetchChartData = async () => {
    if (!selectedChannel) return;
    setLoading(true);
    setError(null);
    try {
      let endpoint;
      switch (selectedChart) {
        case 'subscribers':
          endpoint = trackedOnly ? `/channels/${selectedChannel}/subscribers-tracked-charts` : `/channels/${selectedChannel}/subscribers-charts`;
          break;
        case 'viewsAll':
          endpoint = trackedOnly ? `/channels/${selectedChannel}/views-all-tracked` : `/channels/${selectedChannel}/views-all`;
          break;
        case 'videosAll':
          endpoint = trackedOnly ? `/channels/${selectedChannel}/library-size-all-tracked` : `/channels/${selectedChannel}/library-size-all`;
          break;
        case 'stackedViews':
          endpoint = trackedOnly ? `/channels/${selectedChannel}/stacked-views-tracked` : `/channels/${selectedChannel}/stacked-views`;
          break;
        case 'stackedLibrary':
          endpoint = trackedOnly ? `/channels/${selectedChannel}/stacked-library-tracked` : `/channels/${selectedChannel}/stacked-library`;
          break;
        default:
          throw new Error('Invalid chart type');
      }
      const response = await getChannelChartData(endpoint);
      setChartData(response.data);
    } catch (err) {
      setError('Failed to fetch chart data. Please try again.');
      console.error('Error fetching chart data:', err);
    } finally {
      setLoading(false);
    }
  };

  const fetchTop10Videos = async () => {
    if (!selectedChannel) return;
    try {
      const response = await getTop10Videos(selectedChannel, top10Type, excludeShorts);
      setTop10Videos(response.data);
    } catch (err) {
      console.error('Error fetching top 10 videos:', err);
    }
  };

  const renderChannelKeyData = () => (
    <Card withBorder>
      {/* <Text fz="md" tt="uppercase" fw={700} ta="center" mb="xs">
        Channel Key Data
      </Text> */}
      <Stack>
        <Group>
        <Text>Subscribers: {formatNumber(summaryData?.totalSubscribers)} </Text>
        <ChangeText change={summaryData?.subscribersChange} />
        </Group>
        <Group>
        <Text>Total Views: {formatNumber(summaryData?.totalViews)} </Text>
        <ChangeText change={summaryData?.viewsChange} />
        </Group>
        <Text>Content Types: {summaryData?.contentTypes.join(' | ')}</Text>
        <Text>YouTube's Topics: {summaryData?.youtubeTopics}</Text>
        <Divider my="xs" />
        <Text>First Upload: {formatDate(summaryData?.firstVideoDate)}</Text>
        <Text>Most Recent Upload: {formatDate(summaryData?.mostRecentUpload)}</Text>
        <Divider my="xs" />
        <Text>First Tracked Video: {formatDate(summaryData?.firstTrackedVideoDate)}</Text>
        <Text>Tracking Started: {formatDate(summaryData?.firstTrackingDate)}</Text>
        <Divider my="xs" />
        <Text c="dimmed" fs="italic">Last Updated: {formatDate(summaryData?.lastUpdated)}</Text>
      </Stack>
    </Card>
  );

  const renderLibraryComposition = () => (
    <Card withBorder>
      {/* <Text fz="md" tt="uppercase" fw={700} ta="center" mb="xs">
        Library Composition
      </Text> */}
      <Text mt="md">Total Videos: {formatNumber(summaryData?.totalVideos)}</Text>
      <Text>% of Library Tracked: {summaryData?.libraryPercentage.toFixed(2)}%</Text>
      <Divider my="xs" />
      <Progress.Root size={40}>
        {Object.entries(summaryData?.videoTypeCounts || {}).map(([type, count], index) => (
          <Tooltip key={type} label={`${type}: ${count}`}>
            <Progress.Section 
              value={(count / (summaryData?.totalVideos || 1)) * 100} 
              color={`hsl(${index * 137.5}, 70%, 50%)`}
            >
              {/* <Progress.Label>{type}</Progress.Label> */}
            </Progress.Section>
          </Tooltip>
        ))}
        <Tooltip label={`Non-tracked: ${(summaryData?.totalVideos || 0) - (summaryData?.trackedVideosCount || 0)}`}>
          <Progress.Section
            value={100 - (summaryData?.libraryPercentage || 0)}
            color="gray"
          >
            {/* <Progress.Label>Non-tracked</Progress.Label> */}
          </Progress.Section>
        </Tooltip>
      </Progress.Root>
      <Divider my="xs" />
      <Text>Tracked Videos: {summaryData?.trackedVideosCount}</Text>
      <Stack>
        {Object.entries(summaryData?.videoTypeCounts || {}).map(([type, count]) => (
          <Text key={type}>{type}: {formatNumber(count)}</Text>
        ))}
      </Stack>
    </Card>
  );

  const renderTop10Table = () => (
    <Card withBorder>
      <Group gap={5} mb="md">
        <Text fs="italic" fw={500}>Daily Top 10</Text>
        <Group>
          <Select
            value={top10Type}
            onChange={(value) => {
              if (value === 'daily' || value === 'total') {
                setTop10Type(value);
              }
            }}
            data={[
              { value: 'daily', label: 'Daily Increase' },
              { value: 'total', label: 'Total Views (Tracked Videos)' }
            ]}
            style={{ width: '100%', maxWidth: '300px' }}
          />
          {shortsCount >= 10 && (
            <Checkbox
              label="Exclude Shorts from table"
              checked={excludeShorts}
              onChange={(event) => setExcludeShorts(event.currentTarget.checked)}
            />
          )}
        </Group>
      </Group>
      <div className="ag-theme-quartz" style={{ height: 400, width: '100%' }}>
        <AgGridReact
          rowData={top10Videos}
          columnDefs={[
            { headerName: 'Rank', field: 'rank', width: 70 },
            { headerName: 'Title', field: 'title', flex: 1 },
            { headerName: 'Type', field: 'videoType', width: 100 },
            { headerName: 'Publish Date', field: 'publishDate', valueFormatter: (params) => formatDate(params.value), type: 'numericColumn' },
            { headerName: 'Views', field: 'views', valueFormatter: (params) => formatNumber(params.value), type: 'rightAligned' }
          ]}
        />
      </div>
    </Card>
  );



  const renderChart = () => {
    if (!chartData || chartData.length === 0 || !summaryData) return null;
  
    const commonProps = {
      startDate: summaryData.firstVideoDate,
      firstTrackedDate: summaryData.firstTrackedVideoDate,
      lastTrackedDate: summaryData.lastUpdated
    };
  
    const isStackedChart = selectedChart.startsWith('stacked');
  
    if (isStackedChart) {
      const areas = visibleAreas.map((type, index) => ({
        key: type,
        color: type.toLowerCase() === 'untracked' ? '#808080' : `hsl(${index * 137.5}, 70%, 50%)`
      }));
      return <StackedAreaChart data={chartData} areas={areas} xAxisDataKey="date" />;
    }
  
    switch (selectedChart) {
      case 'subscribers':
        return <SubscribersChart data={chartData} {...commonProps} />;
      case 'viewsAll':
        return <ViewsAllChart data={chartData} {...commonProps} />;
      case 'videosAll':
        return <LibrarySizeAllChart data={chartData} {...commonProps} />;
      default:
        return (
          <AreaChart
            data={chartData}
            dataKey="value"
            color="#0d3fa6"
            xAxisDataKey="date"
          />
        );
    }
  };


  return (
    <Stack gap="md">
      <Group gap={5}>
        {navItems.map((item, index) => (
          <React.Fragment key={item.title}>
            {index > 0 && index < 2 && <IconChevronRight size={14} color='#868e96'/>}
            {index > 1 && <Text size="sm" color="dimmed">|</Text>}
            {item.isExternal ? (
              <Text
                component="a"
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
                size="sm"
                c="#0982eb"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {item.title}
                <IconExternalLink size={14} style={{ marginLeft: 5 }} />
              </Text>
            ) : (
              <Text
                component={Link}
                to={item.href}
                size="sm"
                fw={index >= 1 && location.pathname === item.href ? 500 : 'normal'}
                td={index >= 1 && location.pathname === item.href ? 'underline' : 'none'}
                c={index < 1 ? 'dimmed' : '#0982eb'}
              >
                {item.title}
              </Text>
            )}
      </React.Fragment>
        ))}
      </Group>
      <Paper p="md" withBorder>
        <ChannelPicker value={selectedChannel} onChange={setSelectedChannel} />
        {loading ? (
          <SkeletonLoader count={4} height={200} />
        ) : error ? (
          <Text color="red" mt="md">{error}</Text>
        ) : summaryData ? (
          <Stack mt="md">
            <Grid>
              <Grid.Col span={6}>{renderChannelKeyData()}</Grid.Col>
              <Grid.Col span={6}>{renderLibraryComposition()}</Grid.Col>
            </Grid>
            {renderTop10Table()}
            <Card withBorder>
              <Group gap={5} mb="md">
                <Group>
                  <Select
                    value={selectedChart}
                    onChange={(value) => setSelectedChart(value || 'subscribers')}
                    data={[
                      { value: 'subscribers', label: 'Subscribers' },
                      { value: 'viewsAll', label: 'Views (all content types)' },
                      { value: 'videosAll', label: 'Library Size (all content types)' },
                      ...(selectedChannel !== EXCLUDED_CHANNEL_ID ? [
                        { value: 'stackedViews', label: 'Stacked Views' },
                        { value: 'stackedLibrary', label: 'Stacked Library Size' },
                        ] : [])
                    ]}
                    style={{ width: '100%', maxWidth: '300px' }}
                  />
                  <Checkbox
                  label="Show tracked period only"
                  checked={trackedOnly}
                  onChange={(event) => setTrackedOnly(event.currentTarget.checked)}
                  />
              </Group>
            </Group>
            {selectedChart.startsWith('stacked') && (
              <Group mb="md">
                {['untracked', ...summaryData?.contentTypes || []].map((type) => (
                  <Checkbox
                    key={type}
                    label={type.charAt(0).toUpperCase() + type.slice(1)}
                    checked={visibleAreas.includes(type)}
                    onChange={(event) => {
                      if (event.currentTarget.checked) {
                        setVisibleAreas([...visibleAreas, type]);
                      } else {
                        // Prevent unchecking 'untracked' !== 'untracked'
                        if (type.toLowerCase()) {
                          setVisibleAreas(visibleAreas.filter(t => t !== type));
                        }
                      }
                    }}
                    // disabled={type.toLowerCase() === 'untracked'}
                  />
                ))}
              </Group>
            )}
              {renderChart()}
            </Card>
          </Stack>
        ) : null}
      </Paper>
    </Stack>
  );
}