import React from 'react';
import { Card, Group, Text, Stack, Badge, Divider } from '@mantine/core';
import { formatNumber, formatRate, formatDate } from '../../../utils/formatter';
import { differenceInDays, parseISO } from 'date-fns';

interface PerformanceCardProps {
  performance: {
    rankAll: number;
    rankType: number;
    totalViews: number;
    totalComments: number;
    totalLikes: number;
    viewsChange: number | null;
    commentsChange: number | null;
    likesChange: number | null;
    conversationRate: number;
    likeRate: number;
  };
  contentType: string;
  lastUpdated: string;
  isOldWithLowViews: boolean;
}

const getRankColor = (rank: number): string => {
  const red = Math.round(255 * (rank - 1) / 49);
  const blue = Math.round(255 * (50 - rank) / 49);
  return `rgb(${red}, 0, ${blue})`;
};

const ChangeText: React.FC<{ change: number | null }> = ({ change }) => {
  if (change === null) return null;
  return (
    <Text color={change >= 0 ? 'green' : 'red'}>
      {change > 0 ? '+' : ''}{formatNumber(change)}
    </Text>
  );
};


export function PerformanceCard({ performance, contentType, lastUpdated, isOldWithLowViews }: PerformanceCardProps) {
  const isPerformanceUpdated = performance.viewsChange !== null && 
                               performance.commentsChange !== null && 
                               performance.likesChange !== null;

  const daysSinceLastUpdate = differenceInDays(new Date(), parseISO(lastUpdated));
  const shouldShowUpdateMessage = (isOldWithLowViews || daysSinceLastUpdate > 2) && !isPerformanceUpdated;

  return (
    <Card withBorder>
      <Stack gap="xs">
          <Group>
          {contentType !== 'All' && contentType !== 'All (excluding Shorts)' && ( 
          <>
          <Text size="sm">Rank: </Text>
            <Badge 
              size="md"
              radius="sm"
              style={{
                backgroundColor: getRankColor(performance.rankType),
                color: 'white',
              }}
            >
              #{performance.rankType}
            </Badge>
            </>
            )}
          </Group>
          <Text size="sm" fs="italic" c="dimmed">All Tracked Videos Rank: #{performance.rankAll}</Text>
        <Divider my="xs" />
        <Group>
          <Text size="sm">Views: {formatNumber(performance.totalViews)}</Text>
          <ChangeText change={performance.viewsChange} />
        </Group>
        <Group>
          <Text size="sm">Comments: {formatNumber(performance.totalComments)}</Text>
          <ChangeText change={performance.commentsChange} />
        </Group>
        <Group>
          <Text size="sm">Likes: {formatNumber(performance.totalLikes)}</Text>
          <ChangeText change={performance.likesChange} />
        </Group>
        <Divider my="sm" />
        <Text size="sm">Conversation Rate: {formatRate(performance.conversationRate)}</Text>
        <Text size="sm">Like Rate: {formatRate(performance.likeRate)}</Text>
        <Divider my="xs" />
        <Text size="sm" c="dimmed" fs="italic">Last Updated: {formatDate(lastUpdated)}</Text>
        {daysSinceLastUpdate > 2 && (
          <>
            <Divider my="sm" />
              {isOldWithLowViews && (
              <Text size="xs" fs="italic" c="dimmed">
                Performance no longer updated as this video no longer meets the criteria for daily tracking. If the thumbnail image is broken, this means that the video has been set to private or deleted and is no longer trackable.
              </Text>
            )}
          </>
        )}
      </Stack>
    </Card>
  );
}