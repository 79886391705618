import React, { useState, useEffect } from 'react';
import { Paper, Group, Text, Stack, Select, Checkbox } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import { IconChevronRight } from '@tabler/icons-react';
import { SkeletonLoader } from '../../../core/SkeletonLoader';
import { ChannelPicker } from '../../components/ChannelPicker';
import { getChannelModelTopicsSwarmData, getChannelYouTubeTopicsSwarmData, getChannelTypesSwarmData, getChannelAgeSwarmData } from '../../../../utils/api';
import { SwarmChart } from '../../components/SwarmChart';
import { DataItem, MetricType, CategoryType } from '../../../../utils/swarmtypes';

const navItems = [
  { title: 'Channels', href: '/channels' },
  { title: 'Model Topics', href: '/channels/model-topics'},
  { title: 'Treemap', href: '/channels/model-topics/treemap'},
  { title: 'Topics UMAP', href: '/channels/model-topics/topic-map'},
  { title: 'Timeline', href: '/channels/model-topics/timeline'},
  { title: 'Swarm', href: '/channels/model-topics/swarm'},
  { title: 'Box Plot', href: '/channels/model-topics/boxplot'},
  { title: 'Topic Data', href: '/channels/model-topics/topic-data'},
  { title: 'Video Data', href: '/channels/model-topics/video-data'},
  { title: 'Write Up (External)', href: '#'}, // add link later
];

export function PerformanceSwarm() {
  const [selectedChannel, setSelectedChannel] = useState<string | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<CategoryType>('model');
  const [excludeShorts, setExcludeShorts] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<DataItem[]>([]);
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      if (!selectedChannel) return;

      setLoading(true);
      setError(null);

      let apiCall;
      switch (selectedCategory) {
        case 'model':
          apiCall = getChannelModelTopicsSwarmData;
          break;
        case 'youtube':
          apiCall = getChannelYouTubeTopicsSwarmData;
          break;
        case 'types':
          apiCall = getChannelTypesSwarmData;
          break;
        case 'age':
          apiCall = getChannelAgeSwarmData;
          break;
      }

      try {
        const response = await apiCall(selectedChannel, 'views', selectedCategory !== 'types' ? excludeShorts : false);
        
        if (response.data && response.data.length > 0) {
          setData(response.data);
        } else {
          setError('No data available for the selected options.');
        }
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to fetch data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedChannel, selectedCategory, excludeShorts]);

  return (
    <Stack gap="xs">
      {/* Navigation items rendering */}
      <Paper p="md" shadow="sm" radius="sm">
        <Stack gap="xs">
          <Group>
            <ChannelPicker value={selectedChannel} onChange={setSelectedChannel} />
            <Select
              value={selectedCategory}
              onChange={(value) => setSelectedCategory(value as CategoryType)}
              data={[
                { value: 'model', label: 'Model Topics' },
                { value: 'youtube', label: 'YouTube Topics' },
                { value: 'types', label: 'Types' },
                { value: 'age', label: 'Content Age' },
              ]}
            />
            {selectedCategory !== 'types' && (
              <Checkbox
                label="Exclude Shorts"
                checked={excludeShorts}
                onChange={(event) => setExcludeShorts(event.currentTarget.checked)}
              />
            )}
          </Group>
          {loading ? (
            <SkeletonLoader count={1} height={600} radius="sm" />
          ) : error ? (
            <Text color="red">{error}</Text>
          ) : data.length > 0 ? (
            <SwarmChart 
              data={data} 
              title={`${selectedCategory === 'model' ? 'Model' : selectedCategory === 'youtube' ? 'YouTube' : selectedCategory === 'types' ? 'Content Types' : 'Content Age'} Scatter Plot`} 
              category={selectedCategory}
            />
          ) : (
            <Text>No data available. Please select a channel and ensure data exists for the chosen options.</Text>
          )}
        </Stack>
      </Paper>
    </Stack>
  );
}