import React, { PureComponent } from 'react';
import { ScatterChart, Scatter, XAxis, YAxis, ZAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { Text } from '@mantine/core';
import { DataItem, CategoryType } from '../../../utils/swarmtypes';
import { formatNumber, formatDate } from '../../../utils/formatter';

interface SwarmChartProps {
  data: DataItem[];
  title: string;
  category: CategoryType;
}

class CustomizedAxisTick extends PureComponent<any> {
  render() {
    const { x, y, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)" fontSize={12}>
          {payload.value}
        </text>
      </g>
    );
  }
}

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload as DataItem;
    return (
      <div style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}>
        <Text size="sm" fw={500} fs="italic">{data.group}</Text>
        <Text size="sm" fw={500}>{data.title}</Text>
        <Text size="sm" c="dimmed">Published: {formatDate(data.publishDate)}</Text>
        <Text size="sm">Type: {data.videoType}</Text>
        <Text size="sm">Views: {formatNumber(data.views)}</Text>
      </div>
    );
  }
  return null;
};

const getCustomColor = (index: number, total: number): string => {
  const red = Math.round(255 * index / (total - 1));
  const blue = Math.round(255 * (total - 1 - index) / (total - 1));
  return `rgb(${red},0,${blue})`;
};

const ageOrder = [
  'In the last week',
  'Between 1 week and 1 month',
  'Between 1 month and 3 months',
  'Between 3 months and 6 months',
  'Older than 6 months'
];

export const SwarmChart: React.FC<SwarmChartProps> = ({ data, title, category }) => {
  let groups = Array.from(new Set(data.map(item => item.group)));
  
  if (category === 'age') {
    groups = ageOrder.filter(age => groups.includes(age));
  } else {
    groups.sort((a, b) => {
      const aTotal = data.filter(d => d.group === a).reduce((sum, d) => sum + d.views, 0);
      const bTotal = data.filter(d => d.group === b).reduce((sum, d) => sum + d.views, 0);
      return bTotal - aTotal;
    });
  }

  return (
    <ResponsiveContainer width="100%" height={600}>
      <ScatterChart margin={{ top: 20, right: 20, bottom: 100, left: 30 }}>
        <XAxis 
          dataKey="group" 
          type="category"
          allowDuplicatedCategory={false}
          interval={0}
          tick={<CustomizedAxisTick />}
          height={60}
        />
        <YAxis 
          dataKey="views"
          name="Views" 
          type="number" 
          scale="log" 
          domain={['auto', 'auto']}
          tickFormatter={(value) => formatNumber(value)}
        />
        <ZAxis range={[50, 400]} />
        <Tooltip content={<CustomTooltip />} />
        {groups.map((group, index) => (
          <Scatter
            key={group}
            name={group}
            data={data.filter(item => item.group === group)}
            fill={getCustomColor(index, groups.length)}
          />
        ))}
      </ScatterChart>
    </ResponsiveContainer>
  );
};