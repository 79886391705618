import React from 'react';
import { Container, Title, Text, List, Blockquote, Image, Group, Stack, Divider } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import { IconChevronRight } from '@tabler/icons-react';

interface ReleaseNote {
  version: string;
  date: string;
  changes: string[];
  newFeatures: string[];
  screenshot?: string;
  quote?: string;
}

interface ReleaseNotesProps {
  notes: ReleaseNote[];
}

const navItems = [
  { title: 'Docs', href: '/docs' },
  { title: 'Help', href: '/docs/help' },
  { title: 'Explainers', href: '/docs/explainers' },
  { title: 'FAQs', href: '/docs/faqs' },
  { title: 'ReadMe', href: '/docs/releases/readme' },
  { title: 'Contact 👋', href: '/docs/releases/contact' },
];

export function ReleaseNotes({ notes }: ReleaseNotesProps) {
  const location = useLocation();

  return (
    <Container size="lg">
      <Stack gap="md">
        {/* Navigation */}
        <Group gap={5}>
          {navItems.map((item, index) => (
            <React.Fragment key={item.title}>
              {index > 0 && <IconChevronRight size={14} color='#868e96' />}
              <Text
                component={Link}
                to={item.href}
                size="sm"
                fw={location.pathname === item.href ? 500 : 'normal'}
                td={location.pathname === item.href ? 'underline' : 'none'}
                c={index === navItems.length - 1 ? 'dimmed' : '#0982eb'}
              >
                {item.title}
              </Text>
            </React.Fragment>
          ))}
        </Group>

        <Title order={1}>Release Notes</Title>

        {notes.map((note, index) => (
          <Stack key={note.version} gap="sm">
            <Title order={2}>{`Version ${note.version} (${note.date})`}</Title>

            {note.screenshot && (
              <Image
                src={note.screenshot}
                alt={`Screenshot for version ${note.version}`}
              />
            )}

            {note.changes.length > 0 && (
              <>
                <Text fw={700}>Changes:</Text>
                <List>
                  {note.changes.map((change, idx) => (
                    <List.Item key={idx}>{change}</List.Item>
                  ))}
                </List>
              </>
            )}

            {note.newFeatures.length > 0 && (
              <>
                <Text fw={700}>New Features:</Text>
                <List>
                  {note.newFeatures.map((feature, idx) => (
                    <List.Item key={idx}>{feature}</List.Item>
                  ))}
                </List>
              </>
            )}

            {note.quote && (
              <Blockquote cite="- Development Team">
                {note.quote}
              </Blockquote>
            )}

            {index < notes.length - 1 && <Divider my="lg" />}
          </Stack>
        ))}
      </Stack>
    </Container>
  );
}

const releaseNotes = [
  {
    version: "No Explainers Yet",
    date: "No date",
    changes: [
      "Coming soon though",
    ],
    newFeatures: [
      "Phone me instead",
    ],
    quote: "No explainers yet."
  },
];

export function HelpPage() {
  return <ReleaseNotes notes={releaseNotes} />;
}