import React from 'react';
import BaseChart from './ChannelBaseChart';

interface ViewsAllChartProps {
  data: Array<{
    date: string;
    preTracking: number | null;
    tracked: number | null;
  }>;
  startDate: string;
  firstTrackedDate: string | null;
  lastTrackedDate: string;
}

const ViewsAllChart: React.FC<ViewsAllChartProps> = ({
  data,
  startDate,
  firstTrackedDate,
  lastTrackedDate
}) => {
  const areas = [
    { dataKey: 'preTracking', stroke: '#8884d8', fill: '#8884d8' },
    { dataKey: 'tracked', stroke: '#82ca9d', fill: '#82ca9d' }
  ];

  // If firstTrackedDate is null, use startDate as a fallback
  const effectiveFirstTrackedDate = firstTrackedDate || startDate;

  return (
    <BaseChart
      data={data}
      xDataKey="date"
      areas={areas}
      startDate={startDate}
      firstTrackedDate={effectiveFirstTrackedDate}
      lastTrackedDate={lastTrackedDate}
    />
  );
};

export default ViewsAllChart;
