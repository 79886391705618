import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';

interface OverlapVideo {
  videoId: string;
  usRank: number;
  gbRank: number;
  title: string;
  usViews: number;
}

interface OverlapTableProps {
  data: OverlapVideo[];
}

export function OverlapTable({ data }: OverlapTableProps) {
  const columnDefs: ColDef[] = [
    { field: 'usRank', headerName: 'US Rank', width: 100, sort: 'asc' as const },
    { field: 'gbRank', headerName: 'GB Rank', width: 100 },
    { field: 'title', headerName: 'Video Title', flex: 1 },
    { 
      field: 'usViews', 
      headerName: 'Views', 
      width: 120,
      valueFormatter: (params) => params.value.toLocaleString()
    },
  ];

  return (
    <div className="ag-theme-quartz" style={{ height: 400, width: '100%' }}>
      <AgGridReact
        rowData={data}
        columnDefs={columnDefs}
        defaultColDef={{
          sortable: true,
          filter: true,
        }}
        animateRows={true}
        domLayout='autoHeight'
      />
    </div>
  );
}