import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Group, Select, Text, Stack, Grid } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ValueFormatterParams, GridReadyEvent } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { IconChevronRight, IconExternalLink } from '@tabler/icons-react';
import { SkeletonLoader } from '../../core/SkeletonLoader';
import { getTrendingDailyData } from '../../../utils/api';
import { formatDateUK } from '../../../utils/dateUtils';
import { CustomDatePicker } from '../components/CustomDatePicker';

interface TrendingVideoData {
  videoId: string;
  rank: number;
  title: string;
  channelName: string;
  publishDate: string;
  views: number;
  comments: number;
  conversationRate: number;
}

const navItems = [
  { title: 'Trending', href: '/trending' },
  { title: 'Yesterday', href: '/trending/yesterday'},
  { title: 'Performance', href: '/trending/performance'},
  { title: 'Networks', href: '/trending/networks'},
  { title: 'Data', href: '/trending/data'},
  { title: 'Notes', href: 'https://brick-river-8a5.notion.site/Trending-Data-11f6777fc95580cda742ec3662c30569', isExternal: true},
];

export function TrendingData() {
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedCountry, setSelectedCountry] = useState<string>('US');
  const [rowData, setRowData] = useState<TrendingVideoData[]>([]);
  const location = useLocation();
  const gridRef = useRef<AgGridReact>(null);

  const columnDefs = useMemo<ColDef[]>(() => [
    { 
      field: 'rank', 
      headerName: 'Rank', 
      sortable: true, 
      filter: 'agNumberColumnFilter', 
      width: 80 
    },
    { 
      field: 'title', 
      headerName: 'Video Title', 
      sortable: true, 
      filter: 'agTextColumnFilter', 
      flex: 2 
    },
    { 
      field: 'channelName', 
      headerName: 'Channel Name', 
      sortable: true, 
      filter: 'agTextColumnFilter', 
      flex: 1 
    },
    { 
      field: 'views', 
      headerName: 'Views', 
      sortable: true, 
      filter: 'agNumberColumnFilter',
      valueFormatter: (params: ValueFormatterParams) => params.value.toLocaleString(),
      flex: 1,
      type: 'numericColumn' 
    },
    { 
      field: 'comments', 
      headerName: 'Comments', 
      sortable: true, 
      filter: 'agNumberColumnFilter',
      valueFormatter: (params: ValueFormatterParams) => params.value.toLocaleString(),
      flex: 1,
      type: 'numericColumn'  
    },
    { 
      field: 'conversationRate', 
      headerName: 'Conversation Rate', 
      sortable: true, 
      filter: 'agNumberColumnFilter',
      valueFormatter: (params: ValueFormatterParams) => `${(params.value).toFixed(2)}`,
      flex: 1,
      type: 'numericColumn'  
    },
    { 
      field: 'publishDate', 
      headerName: 'Publish Date', 
      sortable: true, 
      filter: 'agDateColumnFilter',
      valueFormatter: (params: ValueFormatterParams) => formatDateUK(params.value),
      flex: 1,
      type: 'rightAligned' 
    },
  ], []);

  const defaultColDef = useMemo(() => ({
    sortable: true,
    filter: true,
  }), []);
  
  useEffect(() => {
    if (selectedDate) {
      fetchData();
    }
  }, [selectedDate, selectedCountry]);
  
  const fetchData = async () => {
    if (!selectedDate) return;
    setLoading(true);
    try {
      const response = await getTrendingDailyData(selectedDate, selectedCountry);
      const processedData: TrendingVideoData[] = response.data.map((item: any) => ({
        videoId: item.videoId,
        rank: item.rank,
        title: item.title,
        channelName: item.channelName,
        publishDate: item.publishDate,
        views: parseInt(item.views || '0'),
        comments: parseInt(item.comments || '0'),
        conversationRate: parseFloat(item.conversationRate || '0')
      }));
      setRowData(processedData);
    } catch (error) {
      console.error('Error fetching trending data:', error);
      // Handle error (e.g., show error message to user)
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
  };

  const handleUpdate = () => {
    fetchData();
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api.sizeColumnsToFit();
  };

  return (
    <Stack gap="xs">
      <Group gap={5}>
      {navItems.map((item, index) => (
          <React.Fragment key={item.title}>
            {index > 0 && index < 2 && <IconChevronRight size={14} color='#868e96'/>}
            {index > 1 && <Text size="sm" color="dimmed">|</Text>}
            {item.isExternal ? (
              <Text
                component="a"
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
                size="sm"
                c="#0982eb"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {item.title}
                <IconExternalLink size={14} style={{ marginLeft: 5 }} />
              </Text>
            ) : (
              <Text
                component={Link}
                to={item.href}
                size="sm"
                fw={index >= 1 && location.pathname === item.href ? 500 : 'normal'}
                td={index >= 1 && location.pathname === item.href ? 'underline' : 'none'}
                c={index < 1 ? 'dimmed' : '#0982eb'}
              >
                {item.title}
              </Text>
            )}
      </React.Fragment>
        ))}
      </Group>

      <Stack gap="xs">
        <Grid gutter="xs">
          <Grid.Col span="content" style={{ display: 'flex', alignItems: 'flex-end' }}>
            <CustomDatePicker onChange={handleDateChange} />
          </Grid.Col>
          <Grid.Col span="content" style={{ display: 'flex', alignItems: 'flex-end' }}>
            <Select
              data={[
                { value: 'US', label: '🇺🇸 United States' },
                { value: 'GB', label: '🇬🇧 United Kingdom'},
              ]}
              value={selectedCountry}
              onChange={(value) => value && setSelectedCountry(value)}
              placeholder="🇺🇸 or 🇬🇧"
            />
          </Grid.Col>
        </Grid>
        {loading ? (
          <SkeletonLoader count={1} height={400} />
        ) : (
          <div className="ag-theme-quartz" style={{ height: 600, width: '100%' }}>
            <AgGridReact
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              animateRows={true}
              domLayout='autoHeight'
            />
          </div>
        )}
      </Stack>
    </Stack>
  );
}