import React, { useState, useEffect } from 'react';
import { Paper, Group, Select, Button, Box, Grid, Text, Stack, Alert } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import { IconChevronRight } from '@tabler/icons-react';
import { DatePickerSingle } from '../../core/DatePickerSingle';
import { SkeletonLoader } from '../../core/SkeletonLoader';
import { ScatterChart, XAxis, YAxis, Tooltip, Scatter, ResponsiveContainer, Cell } from 'recharts';
import { getTrendingDailyData } from '../../../utils/api';
import { formatDateUK } from '../../../utils/dateUtils';

// Utility function to check if the current path matches or starts with the given href
const isActive = (currentPath: string, href: string) => {
  if (href === '/trending' && currentPath.startsWith('/trending')) return true;
  if (href === '/trending/performance' && currentPath.startsWith('/trending/performance')) return true;
  return currentPath === href;
};

const navItems = [
  { title: 'Experiments', href: '/experiments' },
  { title: 'Source / Subsidiary', href: '/experiments/source-subsidiary' },
  { title: 'Clusters', href: '/experiments/source-subsidiary/clusters' },
  { title: 'Compare', href: '/experiments/source-subsidiary/compare'},
  { title: 'Data', href: '/experiments/source-subsidiary/data'},
  { title: 'Notes (External)', href: '#' }, // TODO: Add this
];

const regionOptions = [
  { value: 'US', label: '🇺🇸 United States' },
  { value: 'GB', label: '🇬🇧 United Kingdom' },
];

const yAxisOptions = [
  { value: 'views', label: 'Views' },
  { value: 'comments', label: 'Comments' },
  { value: 'conversationRate', label: 'Conversation Rate' },
];

export function SourceSubsidiaryClusters() {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedRegion, setSelectedRegion] = useState<string>('US');
  const [selectedYAxis, setSelectedYAxis] = useState<string>('views');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [scatterData, setScatterData] = useState<any[]>([]);
  const location = useLocation();

  useEffect(() => {
    fetchData();
  }, [selectedDate, selectedRegion, selectedYAxis]);
  
  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await getTrendingDailyData(selectedDate, selectedRegion);
      setScatterData(response.data);
    } catch (err) {
      setError('Failed to fetch trending data. Please try again later.');
      console.error('Error fetching trending data:', err);
    } finally {
      setLoading(false);
    }
  };
  
  const handleApply = () => {
    if (selectedYAxis !== 'views') {
      setError('Only Views is currently supported for the Y-axis. Please select Views.');
      return;
    }
    fetchData();
  };

  const renderScatterChart = () => (
    <Box>
      <Text ta="left" size="lg" w={500} mb="sm">Trending Rank/Views</Text>
      <ResponsiveContainer width="100%" height={500}>
        <ScatterChart margin={{ top: 20, right: 20, bottom: 20, left: 40 }}>
          <XAxis 
            type="number" 
            dataKey="rank" 
            name="Rank" 
            domain={[1, 50]}
            ticks={[1, 10, 20, 30, 40, 50]}
            label={{ value: 'Rank', position: 'bottom' }}
          />
          <YAxis 
            type="number" 
            dataKey="views" 
            name="Views" 
            tickFormatter={(value) => new Intl.NumberFormat('en-US', { notation: 'compact', compactDisplay: 'short' }).format(value)}
            label={{ value: 'Views', angle: -90, position: 'insideLeft', offset: -10 }}
          />
          <Tooltip 
            cursor={{ strokeDasharray: '3 3' }}
            content={({ payload, label }) => {
              if (payload && payload.length) {
                const video = payload[0].payload;
                return (
                  <div style={{ backgroundColor: '#fafafa', padding: '10px', border: '1px solid #ccc' }}>
                    <p>{video.title}</p>
                    <p>Channel: {video.channelName}</p>
                    <p>Published: {formatDateUK(video.publishDate)}</p>
                    <p>Views: {video.views.toLocaleString()}</p>
                    <p>Trending Rank: {video.rank}</p>
                  </div>
                );
              }
              return null;
            }}
          />
          <Scatter name="Videos" data={scatterData}>
            {scatterData.map((entry, index) => {
              const rank = entry.rank;
              const red = Math.round(255 * (rank - 1) / 49);
              const blue = Math.round(255 * (50 - rank) / 49);
              return (
                <Cell key={`cell-${index}`} fill={`rgb(${red},0,${blue})`} />
              );
            })}
          </Scatter>
        </ScatterChart>
      </ResponsiveContainer>
    </Box>
  );
  
  return (
    <Stack gap="xs">
      <Group gap={5}>
        {navItems.map((item, index) => (
          <React.Fragment key={item.title}>
            {index > 0 && index < 2 && <IconChevronRight size={14} color='#868e96'/>}
            {index > 1 && <Text size="sm" color="dimmed">|</Text>}
            <Text
              component={Link}
              to={item.href}
              size="sm"
              fw={index >= 1 && isActive(location.pathname, item.href) ? 500 : 'normal'}
              td={index >= 1 && isActive(location.pathname, item.href) ? 'underline' : 'none'}
              c={index < 1 ? 'dimmed' : '#0982eb'}
            >
              {item.title}
            </Text>
          </React.Fragment>
        ))}
      </Group>
      <Paper p="md" shadow="sm" radius="sm">
        <Stack gap="xs">
          <Grid gutter="xs">
            <Grid.Col span="content">
              <DatePickerSingle 
                value={selectedDate} 
                onChange={(date) => setSelectedDate(date || new Date())}
                label="Select date"
              />
            </Grid.Col>
            <Grid.Col span="content">
              <Select
                data={regionOptions}
                value={selectedRegion}
                onChange={(value) => setSelectedRegion(value || 'US')}
                label="Select region"
              />
            </Grid.Col>
            <Grid.Col span="content">
              <Select
                data={yAxisOptions}
                value={selectedYAxis}
                onChange={(value) => setSelectedYAxis(value || 'views')}
                label="Select Y-axis"
              />
            </Grid.Col>
            <Grid.Col span="content" style={{ display: 'flex', alignItems: 'flex-end' }}>
              <Button onClick={handleApply} color="#0982eb">Apply</Button>
            </Grid.Col>
          </Grid>
          {error && (
            <Alert color="red" title="Error">
              {error}
            </Alert>
          )}
          {loading ? (
            <SkeletonLoader count={1} height={500} radius="sm" />
          ) : (
            renderScatterChart()
          )}
        </Stack>
      </Paper>
    </Stack>
  );
}