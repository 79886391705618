import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { TextInput, PasswordInput, Button, Box, Text, Container, Stack } from '@mantine/core';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ReactDOMServer from 'react-dom/server';

// Import SVG icons
import { ReactComponent as PodcastIcon } from '../icons/podcast.svg';
import { ReactComponent as VideoIcon } from '../icons/video.svg';
import { ReactComponent as ShortIcon } from '../icons/short.svg';

interface Node extends d3.SimulationNodeDatum {
  id: number;
  group: number;
}

interface Link {
  source: number | Node;
  target: number | Node;
}

interface ChartType {
  update: (data: { nodes: Node[], links: Link[] }) => void;
}

const Login: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const svgRef = useRef<SVGSVGElement>(null);
  const [chart, setChart] = useState<ChartType | null>(null);

  useEffect(() => {
    if (svgRef.current) {
      const newChart = createForceDirectedGraph();
      setChart(newChart);
    }
  }, []);

  useEffect(() => {
    if (chart) {
      const stages = 5;
      let currentStage = 0;

      const generateData = (stage: number) => {
        const nodes: Node[] = Array.from({ length: 50 }, (_, i) => ({
          id: i,
          group: Math.floor(Math.random() * 3)
        }));
        const links: Link[] = Array.from({ length: Math.min(100, 50 + stage * 10) }, () => ({
          source: Math.floor(Math.random() * nodes.length),
          target: Math.floor(Math.random() * nodes.length)
        }));
        return { nodes, links };
      };

      const updateStage = () => {
        const data = generateData(currentStage);
        chart.update(data);
        currentStage = (currentStage + 1) % stages;
      };

      updateStage(); // Initial update
      const interval = setInterval(updateStage, 3000); // Update every 3 seconds

      return () => clearInterval(interval); // Cleanup on unmount
    }
  }, [chart]);

  const createForceDirectedGraph = (): ChartType => {
    const svg = d3.select(svgRef.current)
        .attr("width", "800px")
        .attr("height", "800px")
        .attr("style", "max-width: 100%; height: auto; background-color: #1a1b1e;");

    const g = svg.append("g");

    const zoom = d3.zoom()
        .scaleExtent([0.1, 4])
        .on("zoom", (event) => {
            g.attr("transform", event.transform);
        });

    svg.call(zoom as any);

    const simulation = d3.forceSimulation<Node>()
        .force("charge", d3.forceManyBody().strength(-30))
        .force("link", d3.forceLink<Node, Link>().id(d => d.id).distance(30))
        .force("x", d3.forceX())
        .force("y", d3.forceY());

    let link = g.append("g")
        .attr("stroke", "#ffffff")
        .attr("stroke-opacity", 0.8)
      .selectAll<SVGLineElement, Link>("line");

    let node = g.append("g")
      .selectAll<SVGGElement, Node>("g");

    const ticked = () => {
      node.attr("transform", d => `translate(${d.x ?? 0},${d.y ?? 0})`);

      link.attr("x1", d => (d.source as Node).x ?? 0)
          .attr("y1", d => (d.source as Node).y ?? 0)
          .attr("x2", d => (d.target as Node).x ?? 0)
          .attr("y2", d => (d.target as Node).y ?? 0);
    }

    simulation.on("tick", ticked);

    const drag = (simulation: d3.Simulation<Node, Link>) => {
      function dragstarted(event: d3.D3DragEvent<SVGGElement, Node, Node>) {
        if (!event.active) simulation.alphaTarget(0.3).restart();
        event.subject.fx = event.subject.x;
        event.subject.fy = event.subject.y;
      }
      
      function dragged(event: d3.D3DragEvent<SVGGElement, Node, Node>) {
        event.subject.fx = event.x;
        event.subject.fy = event.y;
      }
      
      function dragended(event: d3.D3DragEvent<SVGGElement, Node, Node>) {
        if (!event.active) simulation.alphaTarget(0);
        event.subject.fx = null;
        event.subject.fy = null;
      }
      
      return d3.drag<SVGGElement, Node>()
          .on("start", dragstarted)
          .on("drag", dragged)
          .on("end", dragended);
    }

    const update = ({nodes, links}: {nodes: Node[], links: Link[]}) => {
      const width = svgRef.current?.clientWidth || 500;
      const height = svgRef.current?.clientHeight || 500;

      simulation.force("x", d3.forceX(width / 2))
                .force("y", d3.forceY(height / 2));

      const old = new Map(node.data().map(d => [d.id, d]));
      nodes = nodes.map(d => Object.assign(old.get(d.id) || {}, d));
      links = links.map(d => Object.assign({}, d));

      node = node
        .data(nodes, d => d.id)
        .join(enter => {
          const nodeGroup = enter.append("g")
            .call(drag(simulation) as any);

          const icons = [PodcastIcon, VideoIcon, ShortIcon];
          nodeGroup.append("svg:foreignObject")
            .attr("width", 20)
            .attr("height", 20)
            .attr("x", -15)
            .attr("y", -15)
            .append("xhtml:body")
            .style("margin", "0")
            .style("padding", "0")
            .style("background-color", "transparent")
            .html(d => {
              const Icon = icons[d.group];
              return ReactDOMServer.renderToString(<Icon width={20} height={20} fill="none" />);
            });

          return nodeGroup;
        });

      link = link
        .data(links)
        .join("line");

      simulation.nodes(nodes);
      simulation.force<d3.ForceLink<Node, Link>>("link")?.links(links);
      simulation.alpha(1).restart();
    }

    return { update };
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/auth/login', { username, password });
      localStorage.setItem('token', response.data.token);
      navigate('/channels/summaries/top-five');
    } catch (error) {
      console.error('Login failed:', error);
      // Handle login error (show message to user)
    }
  };

  return (
    <Box style={{ display: 'flex', height: '100vh', margin: 0 }}>
      <Box style={{ 
        flex: 1,
        backgroundColor: '#1a1b1e', 
        padding: '2rem', 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'space-between'
      }}>
        <div style={{ width: '100%', height: 'calc(100% - 200px)', position: 'relative' }}>
          <svg ref={svgRef} style={{ 
            width: '100%', 
            height: '100%', 
            position: 'absolute', 
            top: 0, 
            left: 0,
            border: '2px solid #ffffff' // This is just to visualize the border, remove in production
          }}></svg>
        </div>
        <Text size="sm" c="white" mt="2rem">A media analytics platform and layer starting with YouTube. Best on desktop – if you're on mobile, try turning your phone. The network graph above will respond: Zoom, drag it around, move the nodes.</Text>
      </Box>
      <Box style={{ 
        flex: 1,
        padding: '2rem',
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'space-between'
      }}>
        <Stack justify="center" style={{ flex: 1 }}>
          <Container size="xs">
            <Text size="xl" ta="center" mb="xl">Welcome to sven.dog/analytics</Text>
            <form onSubmit={handleSubmit}>
              <TextInput
                required
                label="Username"
                value={username}
                onChange={(event) => setUsername(event.currentTarget.value)}
                mb="md"
              />
              <PasswordInput
                required
                label="Password"
                value={password}
                onChange={(event) => setPassword(event.currentTarget.value)}
                mb="md"
              />
              <Button type="submit" fullWidth mt="md" color="#0982eb">
                Log in
              </Button>
            </form>
            <Text size="sm" mt="md" ta="center">
              Access via invite only. <a href="https://forms.gle/BX4qyAuTPjPDJnWG6" target="_blank" rel="noopener noreferrer">Request your invite here</a>.
            </Text>
          </Container>
        </Stack>
        <Text size="xs" c="dimmed" ta="center" fs="italic">sven.dog/analytics © last.works 2024</Text>
      </Box>
    </Box>
  );
};

export default Login;