import axios from 'axios';
import { format } from 'date-fns';

const api = axios.create({
  baseURL: '/api',
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Logout API call
export const logout = () => api.post('/auth/logout');

// Trending API calls
export const getTrendingSummary = (selectedDate: Date) => {
  const formattedDate = format(selectedDate, 'yyyy-MM-dd');
  return api.get('/trending/summary', { params: { date: formattedDate } });
};

export const getTrendingVideos = (selectedDate: Date, region: string, page: number = 1, pageSize: number = 50) => {
  const formattedDate = format(selectedDate, 'yyyy-MM-dd');
  return api.get('/trending/videos', { params: { date: formattedDate, region, page, pageSize } });
};



// Trending Networks API calls
export const getTrendingNetwork = (selectedDate: Date, region: string) => {
  const formattedDate = format(selectedDate, 'yyyy-MM-dd');
  return api.get('/trending-networks/trending-network', { params: { date: formattedDate, region } });
};

// Trending Available Dates API calls
export const getTrendingAvailableDates = () => api.get('/trending/available-dates');

// Channel API calls
export const getFullyTrackedChannels = () => 
  api.get('/channels/fully-tracked');

// Channel Calendar API calls
export const getChannelCalendarData = (channelId: string, contentType: string = 'All') =>
  api.get(`/channels/${channelId}/calendar`, { params: { contentType } });

// Channel Treemap API calls
interface TreemapResponse {
  data: any;
  channelName: string;
  channelViews: number;
}

export const getChannelTopicsTreemapData = async (channelId: string): Promise<TreemapResponse> => {
  const response = await api.get(`/channels/${channelId}/topics-treemap`);
  return {
    data: response.data,
    channelName: await getChannelName(channelId),
    channelViews: await getChannelViews(channelId)
  };
};

export const getChannelWikipediaTopicsTreemapData = async (channelId: string): Promise<TreemapResponse> => {
  const response = await api.get(`/channels/${channelId}/wikipedia-topics-treemap`);
  return {
    data: response.data,
    channelName: await getChannelName(channelId),
    channelViews: await getChannelViews(channelId)
  };
};

export const getChannelTypesTreemapData = async (channelId: string): Promise<TreemapResponse> => {
  const response = await api.get(`/channels/${channelId}/types-treemap`);
  return {
    data: response.data,
    channelName: await getChannelName(channelId),
    channelViews: await getChannelViews(channelId)
  };
};

async function getChannelName(channelId: string): Promise<string> {
  const response = await api.get(`/channels/${channelId}/summary`);
  return response.data.channelName;
}

async function getChannelViews(channelId: string): Promise<number> {
  const response = await api.get(`/channels/${channelId}/summary`);
  return Number(response.data.totalViews);
}

// Channel Model Topics Box Plot API call
export const getChannelModelTopicsBoxPlotData = (channelId: string) =>
  api.get(`/channels/${channelId}/model-topics-boxplot`);

// Channel Model Topics Grid API call
export const getChannelModelTopicsGridData = (channelId: string) =>
  api.get(`/channels/${channelId}/model-topics-grid`);

// Channel Model Topics Video Grid API call
export const getChannelModelTopicsVideoGridData = (channelId: string, page: number = 1, pageSize: number = 50) =>
  api.get(`/channels/${channelId}/model-topics-video-grid`, { params: { page, pageSize } });

// Channel Performance 2D Scatter API call
export const getChannelPerformanceData = (channelId: string) => 
  api.get(`/channels/${channelId}/performance`);

// Channel Performance 3D Scatter API call
export const getChannelPerformance3DData = (channelId: string) => 
  api.get(`/channels/${channelId}/performance-3d`);

// Channel Performance Top 5 API call
export const getChannelTop5Data = (channelId: string, contentType: string) => 
  api.get(`/channels/${channelId}/top-5`, { params: { contentType } });

// Trending V2s
// GET /api/trending/trending-daily-data  
export const getTrendingDailyData = (date: Date, region: string) => 
  api.get('/trending/trending-daily-data', {
    params: {
      date: date.toISOString().split('T')[0],
      region
    }
  });
// GET /api/trending/trending-carousel-data
export const getTrendingCarouselData = (date: Date, region: string) => 
  api.get('/trending/trending-carousel-data', {
    params: {
      date: date.toISOString().split('T')[0],
      region
    }
  });

// GET /api/trending/trending-carousel-data
export const getTrendingOverlap = (date: Date) => 
  api.get('/trending/trending-overlap', {
    params: {
      date: date.toISOString().split('T')[0]
    }
  });

// Channel Networks API call
export const getChannelNetwork = (channelId: string, edgeTypes: string[] = []) => {
  const params = new URLSearchParams();
  if (edgeTypes.length > 0) {
    params.append('edgeType', edgeTypes.join(','));
  }
  return api.get(`/channel-networks/${channelId}${params.toString() ? `?${params.toString()}` : ''}`);
};

// Video Subgraph API calls
// Video Subgraph API call
export const getVideoSubgraph = (channelId: string, videoId: string) => {
  return api.get(`/channel-networks/${channelId}/video-subgraph/${videoId}`);
};

// GET UMAP
export const getChannelUMAPData = (channelId: string) => {
  return api.get(`/channels/${channelId}/umap-data`);
};

export const getTopicSummary = (channelId: string, topicId: string) => {
  return api.get(`/channels/${channelId}/topic-summary/${topicId}`);
};

// GET Channel Videos Data
export const getChannelVideosData = (channelId: string, page: number, pageSize: number) =>
  api.get(`/channels/${channelId}/videos-data`, { params: { page, pageSize } });

// GET Channel Summary
export const getChannelSummary = (channelId: string) =>
  api.get(`/channels/${channelId}/summary`);

// GET Channel Chart Options
export const getChannelChartOptions = (channelId: string) =>
  api.get(`/channels/${channelId}/chart-options`);

// GET Channel Subgraph
export const getSubgraph = (channelId: string, nodeId: string) =>
  api.get(`/channel-networks/${channelId}/subgraph/${nodeId}`);

// GET Node Types
export const getNodeTypes = (channelId: string) =>
  api.get(`/channel-networks/${channelId}/node-types`);  

// GET Nodes of Type
export const getNodesOfType = (channelId: string, nodeType: string) =>
  api.get(`/channel-networks/${channelId}/nodes/${nodeType}`);

// GET Content Types
export const getContentTypes = (channelId: string) =>
  api.get<{ contentTypes: string[], hasShorts: boolean }>(`/channels/${channelId}/content-types`);

// Video Page API Calls
// Get Channel Videos for VideoPicker
export const getChannelVideos = async (channelId: string, contentType: string) => {
  try {
    const response = await api.get(`/channels/${channelId}/videos`, {
      params: { contentType }
    });
    
    return response.data.map((video: any) => ({
      id: video.id,
      title: video.title,
      type: video.type,
      views: video.views,
      publishDate: video.publishDate
    }));
  } catch (error) {
    console.error('Error fetching channel videos:', error);
    throw error;
  }
};

// INTERFACES FOR VIDEOS PAGE
interface VideoMetadata {
  id: string;
  title: string;
  publishDate: string;
  type: string;
  modelTopic: string;
  wikipediaTopic: string;
  videoLength: string;
  firstTrackedDate: string;
}

interface VideoPerformance {
  totalViews: number;
  totalLikes: number;
  totalComments: number;
  viewsChange: number | null;
  likesChange: number | null;
  commentsChange: number | null;
  conversationRate: number;
  likeRate: number;
  rankAll: number;
  rankType: number;
  lastUpdated: string;
}

interface PerformanceHistoryItem {
  date: string;
  views: number;
  likes: number;
  comments: number;
  daysLive: number;
}

// GET Videos Page calls
export const getChannelVideosPage = (channelId: string, videoId: string) =>
  api.get<VideoMetadata>(`/channels/${channelId}/videos-page/${videoId}/metadata`);

export const getChannelVideosPagePerformance = (channelId: string, videoId: string) =>
  api.get<VideoPerformance>(`/channels/${channelId}/videos-page/${videoId}/performance`);

export const getChannelVideosPagePerformanceHistory = (channelId: string, videoId: string) =>
  api.get<PerformanceHistoryItem[]>(`/channels/${channelId}/videos-page/${videoId}/performance-history`);

// Video Page Bar Chart Daily call
export const getVideoPerformanceDailyData = (channelId: string, videoId: string) =>
  api.get(`/channels/${channelId}/videos/${videoId}/performance/daily`);

// Video Page Total Chart call
export const getVideoPerformanceTotalData = (channelId: string, videoId: string) =>
  api.get(`/channels/${channelId}/videos/${videoId}/performance/total`);

// GET Video Page Available Charts
export const getVideoAvailableCharts = (channelId: string, videoId: string) =>
  api.get(`/channels/${channelId}/videos/${videoId}/available-charts`);

// GET Video Page Relative Chart
export const getVideoPerformanceRelativeData = (channelId: string, videoId: string) =>
  api.get(`/channels/${channelId}/videos/${videoId}/performance/relative`);


// GET Swarm Chart calls
export const getChannelModelTopicsSwarmData = (channelId: string, metric: string, excludeShorts: boolean) =>
  api.get(`/channels/${channelId}/model-topics-swarm`, { params: { metric, excludeShorts } });

export const getChannelYouTubeTopicsSwarmData = (channelId: string, metric: string, excludeShorts: boolean) =>
  api.get(`/channels/${channelId}/youtube-topics-swarm`, { params: { metric, excludeShorts } });

export const getChannelTypesSwarmData = (channelId: string, metric: string) =>
  api.get(`/channels/${channelId}/types-swarm`, { params: { metric } });

export const getChannelAgeSwarmData = (channelId: string, metric: string, excludeShorts: boolean) =>
  api.get(`/channels/${channelId}/age-swarm`, { params: { metric, excludeShorts } });

// GET Timeline V2
export const getChannelTimelineData = (channelId: string, timelineType: string) =>
  api.get(`/channels/${channelId}/timeline?type=${encodeURIComponent(timelineType)}`);

export const getChannelTimelineSummary = (channelId: string, timelineType: string) =>
  api.get(`/channels/${channelId}/timeline-summary?type=${encodeURIComponent(timelineType)}`)

// Top 10 Videos API call
export const getTop10Videos = (channelId: string, type: 'daily' | 'total', excludeShorts: boolean) =>
  api.get(`/channels/${channelId}/top-10-videos`, {
    params: { type, excludeShorts }
  });

// Channel Chart API calls
export const getChannelChartData = (endpoint: string) =>
  api.get(endpoint);

// Stacked Views API calls for Channels
export const getStackedViews = (channelId: string) =>
  api.get(`/api/channels/${channelId}/stacked-views`);

export const getStackedViewsTracked = (channelId: string) =>
  api.get(`/api/channels/${channelId}/stacked-views-tracked`);

export const getStackedLibrary = (channelId: string) =>
  api.get(`/api/channels/${channelId}/stacked-library`);

export const getStackedLibraryTracked = (channelId: string) =>
  api.get(`/api/channels/${channelId}/stacked-library-tracked`);

// Enhanced UMAP Data API call
export const getEnhancedChannelUMAPData = (channelId: string) => {
  return api.get(`/channels/${channelId}/enhanced-umap-data`);
};

// Mega Grid API call
export const getChannelVideosGridData = (
  channelId: string, 
  startRow: number, 
  pageSize: number, 
  sortModel: any[]
) => {
  return api.get(`/channels/${channelId}/videos-grid-data`, {
    params: { startRow, pageSize, sortModel: JSON.stringify(sortModel) }
  });
};

export default api;