import React, { useState, useEffect, useMemo } from 'react';
import { Paper, Group, Text, Stack, Select } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import { IconChevronRight } from '@tabler/icons-react';
import { SkeletonLoader } from '../../core/SkeletonLoader';
import { ChannelPicker } from '../components/ChannelPicker';
import { getChannelTimelineData } from '../../../utils/api';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, TooltipProps } from 'recharts';
import { formatNumber, formatDate } from '../../../utils/formatter';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { ColDef, ICellRendererParams } from 'ag-grid-community';

interface TimelineDataPoint {
  date: string;
  [key: string]: string | number;
}

interface CategorySummary {
  category: string;
  totalVolume: number;
  selected: boolean;
}

const navItems = [
  { title: 'Channels', href: '/channels' },
  { title: 'Timeline', href: '/channels/timeline'},
  // ... other nav items
];

const timelineTypes = [
  { value: 'modelTopics', label: 'Model Topics' },
  { value: 'youtubeTopics', label: 'YouTube Topics' },
  { value: 'contentTypes', label: 'Content Types' },
];

export function ChannelTimeline() {
  const [selectedChannel, setSelectedChannel] = useState<string | null>(null);
  const [selectedTimelineType, setSelectedTimelineType] = useState<string>('modelTopics');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [timelineData, setTimelineData] = useState<TimelineDataPoint[]>([]);
  const [categorySummary, setCategorySummary] = useState<CategorySummary[]>([]);
  const location = useLocation();

  useEffect(() => {
    if (selectedChannel && selectedTimelineType) {
      fetchData();
    }
  }, [selectedChannel, selectedTimelineType]);

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await getChannelTimelineData(selectedChannel!, selectedTimelineType);
      setTimelineData(response.data);
      
      // Calculate category summaries
      const summaries: { [key: string]: number } = {};
      response.data.forEach((item: TimelineDataPoint) => {
        Object.entries(item).forEach(([key, value]) => {
          if (key !== 'date') {
            summaries[key] = (summaries[key] || 0) + (Number(value) || 0);
          }
        });
      });
      
      const summaryArray = Object.entries(summaries)
        .map(([category, totalVolume]) => ({ category, totalVolume, selected: false }))
        .sort((a, b) => b.totalVolume - a.totalVolume);
      
      // Initially select top 5 categories (or all if less than 5)
      const initialSelectedCount = Math.min(5, summaryArray.length);
      summaryArray.forEach((item, index) => {
        item.selected = index < initialSelectedCount;
      });
      
      setCategorySummary(summaryArray);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Failed to fetch data');
    } finally {
      setLoading(false);
    }
  };

  const getCustomColor = (index: number, total: number): string => {
    const red = Math.round(255 * index / (total - 1));
    const blue = Math.round(255 * (total - 1 - index) / (total - 1));
    return `rgb(${red},0,${blue})`;
  };

  const CustomTooltip = ({ active, payload, label }: TooltipProps<number, string>) => {
    if (active && payload && payload.length) {
      return (
        <div style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}>
          <Text size="sm" fw={500} fs="italic">{formatDate(label)}</Text>
          {payload.map((entry, index) => (
            entry.value && entry.value > 0 ? (
              <Text key={`item-${index}`} size="sm" style={{ color: entry.color }}>
                {`${entry.name}: ${formatNumber(entry.value as number)}`}
              </Text>
            ) : null
          ))}
        </div>
      );
    }
    return null;
  };

  const renderChart = () => {
    const selectedCategories = categorySummary.filter(cat => cat.selected).map(cat => cat.category);
    return (
      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={timelineData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" tickFormatter={formatDate} />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          {selectedCategories.map((category, index) => (
            <Bar 
              key={category} 
              dataKey={category} 
              stackId="a" 
              fill={getCustomColor(index, selectedCategories.length)} 
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    );
  };

  const columnDefs: ColDef[] = [
    {
      headerName: 'Select',
      field: 'selected',
      width: 100,
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <input
            type="checkbox"
            checked={params.value}
            onChange={() => {
              const newData = [...categorySummary];
              const index = newData.findIndex(item => item.category === params.data.category);
              newData[index].selected = !newData[index].selected;
              setCategorySummary(newData);
            }}
          />
        );
      }
    },
    { 
      headerName: selectedTimelineType === 'contentTypes' ? 'Type' : 'Topic', 
      field: 'category', 
      flex: 1 
    },
    { 
      headerName: 'Volume', 
      field: 'totalVolume', 
      flex: 1,
      valueFormatter: (params) => formatNumber(params.value)
    }
  ];

  return (
    <Stack gap="xs">
      <Group gap={5}>
        {navItems.map((item, index) => (
          <React.Fragment key={item.title}>
            {index > 0 && <IconChevronRight size={14} color='#868e96'/>}
            <Text
              component={Link}
              to={item.href}
              size="sm"
              fw={location.pathname === item.href ? 500 : 'normal'}
              td={location.pathname === item.href ? 'underline' : 'none'}
              c={index === 0 ? 'dimmed' : '#0982eb'}
            >
              {item.title}
            </Text>
          </React.Fragment>
        ))}
      </Group>
      <Paper p="md" shadow="sm" radius="sm">
        <Stack gap="md">
          <Group>
            <ChannelPicker value={selectedChannel} onChange={setSelectedChannel} />
            <Select
              data={timelineTypes}
              value={selectedTimelineType}
              onChange={(value) => setSelectedTimelineType(value || 'modelTopics')}
            />
          </Group>
          {loading ? (
            <SkeletonLoader count={1} height={400} radius="sm" />
          ) : error ? (
            <Text color="red">{error}</Text>
          ) : timelineData.length > 0 ? (
            <>
              {renderChart()}
              <div className="ag-theme-quartz" style={{ height: 400, width: '100%' }}>
                <AgGridReact
                  rowData={categorySummary}
                  columnDefs={columnDefs}
                  domLayout='autoHeight'
                />
              </div>
            </>
          ) : (
            <Text>No data available. Please select a channel and timeline type.</Text>
          )}
        </Stack>
      </Paper>
    </Stack>
  );
}