import React from 'react';
import { Card, Group, Text, Stack, Image, Box, Anchor, HoverCard, Divider } from '@mantine/core';
import { IconExternalLink } from '@tabler/icons-react';
import { ContentTypeBadge } from './ContentTypeBadge';
import { formatDate, formatSeconds } from '../../../utils/formatter';

interface VideoInfoCardProps {
  metadata: {
    id: string;
    title: string;
    publishDate: string;
    type: string;
    wikipediaTopic: string;
    modelTopic: string;
    videoLength: string;
  };
  firstTrackedDate: string;
}

const CroppedThumbnail: React.FC<{ videoId: string; title: string; type: string }> = ({ videoId, title, type }) => {
  const isShort = type === 'Short';
  const thumbnailUrl = isShort
    ? `https://i.ytimg.com/vi/${videoId}/oar2.jpg`
    : `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;

  return (
    <Box style={{ width: '100%', height: 0, paddingBottom: '56.25%', position: 'relative' }}>
      <Image
        src={thumbnailUrl}
        alt={title}
        fit="cover"
        style={{
          position: 'absolute',
          top: 0,
          left: isShort ? '50%' : 0,
          transform: isShort ? 'translateX(-50%)' : 'none',
          width: isShort ? 'auto' : '100%',
          height: '100%',
          objectFit: 'cover'
        }}
      />
    </Box>
  );
};

export function VideoInfoCard({ metadata, firstTrackedDate }: VideoInfoCardProps) {
  const videoUrl = metadata.type === 'Short'
    ? `https://youtube.com/shorts/${metadata.id}`
    : `https://youtube.com/watch?v=${metadata.id}`;

  return (
    <Card withBorder>
      <Group align="flex-start" wrap="nowrap">
      <Box style={{ width: '40%', maxWidth: '300px' }}>
          <HoverCard width={280} shadow="md" openDelay={300}>
            <HoverCard.Target>
              <div>
                <CroppedThumbnail videoId={metadata.id} title={metadata.title} type={metadata.type} />
              </div>
            </HoverCard.Target>
            <HoverCard.Dropdown>
              <Anchor 
                href={videoUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Group>
                  <Text size="sm">Watch on YouTube</Text>
                  <IconExternalLink size={14} />
                </Group>
              </Anchor>
            </HoverCard.Dropdown>
          </HoverCard>
        </Box>
        <Stack gap="xs" style={{ flex: 1}}>
          <Text fw={500}>{metadata.title}</Text>
          <ContentTypeBadge type={metadata.type} size="md" />
          <Text size="sm" c="dimmed">Published: {formatDate(metadata.publishDate)}</Text>
          <Text size="sm" c="dimmed">Length: {formatSeconds(parseInt(metadata.videoLength))}</Text>
          <Divider my="xs" />
          <Text size="sm" c={metadata.wikipediaTopic ? undefined : "dimmed"} fs={metadata.wikipediaTopic ? undefined : "italic"}>
            YouTube's Topic: {metadata.wikipediaTopic || "Not assigned by YouTube"}
          </Text>
          <Text size="sm" c={metadata.modelTopic ? undefined : "dimmed"} fs={metadata.modelTopic ? undefined : "italic"}>
            Model Topic: {metadata.modelTopic || "Not Modelled"}
          </Text>
          <Divider my="xs" />
          <Text size="sm" c="dimmed" fs="italic">First Ingested: {formatDate(firstTrackedDate)}</Text>
        </Stack>
      </Group>
    </Card>
  );
}