import React, { useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { FaqPage } from './Faqs';
import { ContactPage } from './Contact';
import { HelpPage } from './Explainers';
import { ReadMePage } from './ReadMe';



export function HelpPages() {
  const { subpage } = useParams<{ subpage: string }>();

  switch (subpage) {
    case 'explainers':
       return <HelpPage/>;
    case 'faqs':
      return <FaqPage/>;
    case 'readme':
      return <ReadMePage/>;
    case 'contact':
      return <ContactPage/>;
    default: return <Navigate to="/help/faqs" replace />;
  }
}

const navItems = [
  { title: 'Docs', href: '/docs' },
  { title: 'Help', href: '/docs/help' },
  { title: 'Explainers', href: '/docs/explainers' },
  { title: 'FAQs', href: '/docs/faqs' },
  { title: 'ReadMe', href: '/docs/releases/readme' },
  { title: 'Contact 👋', href: '/docs/releases/contact' },
];