import React, { useState, useEffect } from 'react';
import { Paper, Group, Text, Stack, Grid, Badge, Card, Image, Box, HoverCard, Tooltip, Divider, Anchor } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import { IconChevronRight, IconExternalLink } from '@tabler/icons-react';
import { ChannelPicker } from '../components/ChannelPicker';
import { ContentTypePicker } from '../components/ContentTypePicker';
import { ContentTypeBadge } from '../components/ContentTypeBadge';
import api from '../../../utils/api';
import { SkeletonLoader } from '../../core/SkeletonLoader';
import { formatNumber, formatDate } from '../../../utils/formatter';


interface VideoData {
  rank: number;
  overallRank: number;
  videoId: string;
  title: string;
  type: string;
  publishDate: string;
  modelTopic: string;
  wikipediaTopicsPretty: string;
  views: number;
  viewsChange: number | null;
  comments: number;
  commentsChange: number | null;
  conversationRate: number;
  likes: number;
  likesChange: number | null;
  likeRate: number;
}

const removeThumbnailLetterboxing = (url: string): string => {
  const height = (360 - (480 / (1280 / 720))) / 2;
  return `${url}#crop=45:${Math.round(height)}:0:0`;
};

const getRankColor = (rank: number): string => {
  const red = Math.round(255 * (rank - 1) / 49);
  const blue = Math.round(255 * (50 - rank) / 49);
  return `rgb(${red}, 0, ${blue})`;
};

const CroppedThumbnail: React.FC<{ videoId: string; title: string; type: string }> = ({ videoId, title, type }) => {
  const isShort = type === 'Short';
  const thumbnailUrl = isShort
    ? `https://i.ytimg.com/vi/${videoId}/oar2.jpg`
    : `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;

  return (
    <Box style={{ width: '100%', height: 0, paddingBottom: '56.25%', position: 'relative' }}>
      <Image
        src={thumbnailUrl}
        alt={title}
        fit="cover"
        style={{
          position: 'absolute',
          top: 0,
          left: isShort ? '50%' : 0,
          transform: isShort ? 'translateX(-50%)' : 'none',
          width: isShort ? 'auto' : '100%',
          height: '100%',
        }}
      />
    </Box>
  );
};

const ChangeIndicator: React.FC<{ value: number | null }> = ({ value }) => {
  if (value === null) return null;
  return (
    <Text size="sm" c={value > 0 ? 'green' : value < 0 ? 'red' : 'dimmed'}>
      {value > 0 ? '+' : ''}{formatNumber(value)}
    </Text>
  );
};

const navItems = [
  { title: 'Channels', href: '/channels' },
  { title: 'Channel', href: '/channels/channel' },
  { title: 'Top 5', href: '/channels/top-five' },
  { title: 'Video', href: '/channels/video' },
  { title: 'Networks', href: '/channels/networks/' },
  { title: 'Timeline', href: '/channels/timeline' },
  { title: 'Performance', href: '/channels/performance' },
  { title: 'Data', href: '/channels/data' },
  { title: 'Notes', href: 'https://brick-river-8a5.notion.site/Channels-Top-5-11f6777fc95580c68bb1daa4c393a70d', isExternal: true },
];

export function ChannelsTopFive() {
  const [selectedChannel, setSelectedChannel] = useState<string | null>(null);
  const [contentType, setContentType] = useState<string>('All');
  const [top5Data, setTop5Data] = useState<VideoData[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();

  useEffect(() => {
    if (selectedChannel) {
      setLoading(true);
      setError(null);
      api.get(`/channels/${selectedChannel}/top-5`, { params: { contentType } })
        .then(response => {
          setTop5Data(response.data);
          setLoading(false);
        })
        .catch(err => {
          setError("Error fetching data. Please try again.");
          setLoading(false);
        });
    }
  }, [selectedChannel, contentType]);

  const renderVideoCard = (video: VideoData) => {
    const isPerformanceUpdated = video.viewsChange !== null && video.commentsChange !== null && video.likesChange !== null;
    const isOldWithLowViews = new Date().getTime() - new Date(video.publishDate).getTime() > 31 * 24 * 60 * 60 * 1000 && video.views < 100000;

    return (
      <Card key={video.videoId} withBorder shadow="sm" radius="md" p="md" style={{ height: '100%' }}>
        <Stack justify="space-between" style={{ height: '100%' }}>
          <div>
            <Group gap="apart" mb="xs">
              <Badge 
                size="lg"
                radius="sm"
                style={{
                  backgroundColor: getRankColor(video.rank),
                  color: 'white',
                  minWidth: '40px',
                  fontWeight: 'bold'
                }}
              >
                #{video.rank}
              </Badge>
              {contentType !== 'All' && (
                <Text size="xs" fs="italic" c="dimmed">All videos rank: #{video.overallRank}</Text>
              )}
            </Group>
            <HoverCard width={280} shadow="md" openDelay={1000}>
              <HoverCard.Target>
                <div>
                  <CroppedThumbnail videoId={video.videoId} title={video.title} type={video.type} />
                </div>
              </HoverCard.Target>
              <HoverCard.Dropdown>
                <Anchor 
                  href={video.type === 'Short' 
                    ? `https://youtube.com/shorts/${video.videoId}`
                    : `https://youtube.com/watch?v=${video.videoId}`
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Group>
                    <Text size="sm">Watch on YouTube</Text>
                    <IconExternalLink size={14} />
                  </Group>
                </Anchor>
              </HoverCard.Dropdown>
            </HoverCard>
            <Tooltip label={video.title} multiline maw={300}>
              <Text fw={500} lineClamp={2} mt="md">{video.title}</Text>
            </Tooltip>
            <Text size="sm" c="dimmed" fs="italic" mt="xs">Published: {formatDate(video.publishDate)}</Text>
            <ContentTypeBadge type={video.type} size="sm" style={{ marginTop: '8px' }} />
            <Divider my="sm" />
            <Group>
              <Text size="sm">Views: {formatNumber(video.views)}</Text>
              <ChangeIndicator value={video.viewsChange} />
            </Group>
            <Group>
              <Text size="sm">Comments: {formatNumber(video.comments)}</Text>
              <ChangeIndicator value={video.commentsChange} />
            </Group>
            <Group>
              <Text size="sm">Likes: {formatNumber(video.likes)}</Text>
              <ChangeIndicator value={video.likesChange} />
            </Group>
            <Divider my="sm" />
            <Text size="sm">Conversation Rate: {video.conversationRate.toFixed(2)}</Text>
            <Text size="sm">Like Rate: {video.likeRate.toFixed(2)}</Text>
            <Divider my="sm" />
            <Text size="sm">YouTube's Topic: {video.wikipediaTopicsPretty}</Text>
            <Text size="sm">Model Topic: {video.modelTopic || "Not modelled"}</Text>
            
            {!isPerformanceUpdated && (
              <>
                <Divider my="sm" />
                <Text size="xs" fs="italic" c="dimmed">
                  {isOldWithLowViews
                    ? "Performance not updated as this video no longer meets the criteria for daily tracking."
                    : "Performance not updated. Possible causes: the video has been set to private or deleted (if the thumbnail image is broken, this is likely) or another error occurred."
                  }
                </Text>
              </>
            )}
          </div>
        </Stack>
      </Card>
    );
  };

  return (
    <Stack gap="md">
      <Group gap={5}>
        {navItems.map((item, index) => (
          <React.Fragment key={item.title}>
            {index > 0 && index < 3 && <IconChevronRight size={14} color='#868e96'/>}
            {index > 1 && <Text size="sm" color="dimmed">|</Text>}
            {item.isExternal ? (
              <Text
                component="a"
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
                size="sm"
                c="#0982eb"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {item.title}
                <IconExternalLink size={14} style={{ marginLeft: 5 }} />
              </Text>
            ) : (
              <Text
                component={Link}
                to={item.href}
                size="sm"
                fw={index >= 2 && location.pathname === item.href ? 500 : 'normal'}
                td={index >= 2 && location.pathname === item.href ? 'underline' : 'none'}
              c={index < 2 ? 'dimmed' : '#0982eb'}
              >
                {item.title}
              </Text>
            )}
      </React.Fragment>
        ))}
      </Group>
      <Paper p="md" radius="md" withBorder>
        <Group gap="apart" mb="md">
          <ChannelPicker value={selectedChannel} onChange={setSelectedChannel} />
          <ContentTypePicker
            channelId={selectedChannel}
            value={contentType}
            onChange={(value) => setContentType(value || 'All')}
          />
        </Group>
        {loading ? (
          <SkeletonLoader count={5} height={400} />
        ) : error ? (
          <Text color="red">{error}</Text>
        ) : (
          <Grid>
            {top5Data.map(video => (
              <Grid.Col key={video.videoId} span={12 / top5Data.length}>
                {renderVideoCard(video)}
              </Grid.Col>
            ))}
          </Grid>
        )}
      </Paper>
    </Stack>
  );
}