
import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { ChannelPerformance2DScatter } from './PerformanceScatterTwoD';
import { ChannelPerformance3DScatter } from './PerformanceScatter';
import { TreemapPage } from './PerformanceTreemap';
import { PerformanceSwarm } from './PerformanceSwarm';

export function ChannelPerformance() {
  const { subpage } = useParams<{ subpage: string }>();

  switch (subpage) {
    case 'two-d':
      return <ChannelPerformance2DScatter />;
    case 'three-d':
      return <ChannelPerformance3DScatter />;
    case 'treemap':
      return <TreemapPage />;
    case 'swarm':
      return <PerformanceSwarm />;
    default:
      return <Navigate to="/channels/performance/two-d" replace />;
  }
}