import React, { useState, useEffect } from 'react';
import { Box, Grid, Card, Text, Group, Accordion, Stack, Alert } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import { SkeletonLoader } from '../../core/SkeletonLoader';
import { TrendingVideoCarousel } from '../../trending/components/TrendingVideoCarousel';
import { IconChevronRight } from '@tabler/icons-react';
import { getTrendingCarouselData, getTrendingOverlap } from '../../../utils/api';
import { formatDateUK } from '../../../utils/dateUtils';
import { OverlapTable } from '../../trending/components/OverlapTable';
import { CustomDatePicker } from '../../trending/components/CustomDatePicker';

// Utility function to check if the current path matches or starts with the given href
const isActive = (currentPath: string, href: string) => {
  if (href === '/trending' && currentPath.startsWith('/trending')) return true;
  if (href === '/trending/summary' && currentPath.startsWith('/trending/summary')) return true;
  return currentPath === href;
};

const navItems = [
  { title: 'Experiments', href: '/experiments' },
  { title: 'Thumbnail Scores', href: '/experiments/thumbnail-scores' },
  { title: 'Scores', href: '/experiments/thumbnail-scores/scores' },
  { title: 'Impact', href: '/experiments/thumbnail-scores/impact' },
  { title: 'Scorecard', href: '/experiments/thumbnail-scores/scorecard' },
  { title: 'Dataset', href: '/experiments/thumbnail-scores/dataset' },
  { title: 'Write Up (External)', href: '#' }, // TODO: Add this
];

export function ThumbnailScoresScores() {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [usCarouselData, setUsCarouselData] = useState<any[]>([]);
  const [gbCarouselData, setGbCarouselData] = useState<any[]>([]);
  const [overlapData, setOverlapData] = useState<any[]>([]);
  const location = useLocation();

  useEffect(() => {
    if (selectedDate) {
      fetchData(selectedDate);
    }
  }, [selectedDate]);

  const fetchData = async (date: Date) => {
    setLoading(true);
    setError(null);
    try {
      const [usCarouselResponse, gbCarouselResponse, overlapResponse] = await Promise.all([
        getTrendingCarouselData(date, 'US'),
        getTrendingCarouselData(date, 'GB'),
        getTrendingOverlap(date)
      ]);

      setUsCarouselData(usCarouselResponse.data);
      setGbCarouselData(gbCarouselResponse.data);
      setOverlapData(overlapResponse.data);

      if (usCarouselResponse.data.length === 0 && gbCarouselResponse.data.length === 0) {
        setError(`No data available for ${formatDateUK(date)}. Please select a different date.`);
      }
    } catch (err) {
      setError('Failed to fetch trending data. Please try again later.');
      console.error('Error fetching trending data:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
  };

  return (
    <Stack gap="xs">
      <Group gap={5}>
        {navItems.map((item, index) => (
          <React.Fragment key={item.title}>
            {index > 0 && index < 2 && <IconChevronRight size={14} color='#868e96'/>}
            {index > 1 && <Text size="sm" color="dimmed">|</Text>}
            <Text
              component={Link}
              to={item.href}
              size="sm"
              fw={index >= 1 && isActive(location.pathname, item.href) ? 500 : 'normal'}
              td={index >= 1 && isActive(location.pathname, item.href) ? 'underline' : 'none'}
              c={index < 1 ? 'dimmed' : '#0982eb'}
            >
              {item.title}
            </Text>
          </React.Fragment>
        ))}
      </Group>
      <Box>
        <Stack gap="md">
          <Group justify="center" gap="sm">
            <CustomDatePicker onChange={handleDateChange} />
          </Group>
          {error && (
            <Alert color="red" title="Error">
              {error}
            </Alert>
          )}
          {loading ? (
            <SkeletonLoader count={2} height={400} radius="sm" />
          ) : (
            <>
              <Grid gutter="md">
                {(['US', 'GB'] as const).map((region) => (
                  <Grid.Col span={6} key={region}>
                    <TrendingVideoCarousel
                      videos={region === 'US' ? usCarouselData : gbCarouselData}
                      region={region}
                    />
                  </Grid.Col>
                ))}
              </Grid>
              <Card withBorder radius="md">
                <Accordion>
                  <Accordion.Item value="overlap">
                    <Accordion.Control>
                      <Text fw={500}>Videos trending in both US & GB: {overlapData.length}</Text>
                    </Accordion.Control>
                    <Accordion.Panel>
                      <OverlapTable data={overlapData} />
                    </Accordion.Panel>
                  </Accordion.Item>
                </Accordion>
              </Card>
            </>
          )}
        </Stack>
      </Box>
    </Stack>
  );
}
