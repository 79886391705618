import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { formatNumber, formatDate } from '../../../utils/formatter';

interface StackedAreaChartProps {
  data: any[];
  areas: { key: string; color: string }[];
  xAxisDataKey: string;
}

const StackedAreaChartComponent: React.FC<StackedAreaChartProps> = ({ data, areas, xAxisDataKey }) => {
  // Sort areas to ensure "untracked" is first (bottom of the stack)
  const sortedAreas = [...areas].sort((a, b) => 
    a.key.toLowerCase() === 'untracked' ? -1 : b.key.toLowerCase() === 'untracked' ? 1 : 0
  );

  return (
    <ResponsiveContainer width="100%" height={400}>
      <AreaChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis 
          dataKey={xAxisDataKey}
          tickFormatter={(tick) => formatDate(tick)}
        />
        <YAxis tickFormatter={(tick) => formatNumber(tick)} />
        <Tooltip 
          formatter={(value: number, name: string) => [formatNumber(value), name]}
          labelFormatter={(label) => formatDate(label as string)}
        />
        {sortedAreas.map((area) => (
          <Area 
            key={area.key}
            // type="monotone" // don't pass type to stop interpolation
            dataKey={area.key} 
            stackId="1"
            stroke={area.color} 
            fill={area.color} 
            fillOpacity={area.key.toLowerCase() === 'untracked' ? 0.1 : 0.6}
            strokeWidth={area.key.toLowerCase() === 'untracked' ? 1 : 2}
            name={area.key}
            connectNulls
          />
        ))}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default StackedAreaChartComponent;