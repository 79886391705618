import React from 'react';
import {
  ComposedChart,
  Line,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { Text } from '@mantine/core';
import { formatNumber, formatDate } from '../../../utils/formatter';

interface RelativeChartDataPoint {
  date: string;
  daysLive: number;
  views: number;
  bounds: [number, number];
}

interface VideoRelativeChartProps {
  data: RelativeChartDataPoint[];
  publishDate: string;
}

const CustomTooltip: React.FC<any> = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}>
        <Text size="sm" fw={500}>Day {data.daysLive}</Text>
        <Text size="sm" fs="italic" c="dimmed">{formatDate(data.date)}</Text>
        <Text size="sm" fw={500}>Views: {formatNumber(data.views)}</Text>
        <Text size="sm">Min.: {formatNumber(data.bounds[0])}</Text>
        <Text size="sm">Max.: {formatNumber(data.bounds[1])}</Text>
      </div>
    );
  }
  return null;
};

export const VideoRelativeChart: React.FC<VideoRelativeChartProps> = ({ data, publishDate }) => {
  return (
    <ResponsiveContainer width="100%" height={600}>
      <ComposedChart
        data={data}
        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis 
          dataKey="daysLive"
          tickFormatter={(value) => `Day ${value}`}
        />
        <YAxis 
          tickFormatter={(value) => formatNumber(value)}
        />
        <Tooltip content={<CustomTooltip />} />
        <Area
          type="monotone"
          dataKey="bounds"
          fill="gray"
          stroke="none"
          fillOpacity={0.2}
          connectNulls
        />
        <Line
          type="monotone"
          dataKey="views"
          stroke="#0d3fa6"
          strokeWidth={3}
          dot={true}
          activeDot={{ r: 8 }}
          connectNulls
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default VideoRelativeChart;