import React, { useState, useEffect } from 'react';
import { Paper, Group, Text, Stack, Grid } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import { IconChevronRight } from '@tabler/icons-react';
import { ChannelPicker } from '../components/ChannelPicker';
import { ContentTypePicker } from '../components/ContentTypePicker';
import { VideoPicker } from '../components/VideoPicker';
import { VideoInfoCard } from '../components/VideoInfoCard';
import { PerformanceCard } from '../components/PerformanceCard';
import DailyGraph from '../components/VideoDailyGraph';
import { TotalChart } from '../components/VideoTotalChart';
import { VideoRelativeChart } from '../components/VideoRelativeChart';
import { SkeletonLoader } from '../../core/SkeletonLoader';
import { 
  getChannelVideosPage, 
  getChannelVideosPagePerformance, 
  getVideoPerformanceDailyData, 
  getVideoPerformanceTotalData,
  getVideoPerformanceRelativeData
} from '../../../utils/api';
import { MetricPicker } from '../components/VideoMetricPicker';
import { GraphTypePicker } from '../components/VideoGraphTypePicker';

type MetricKey = 'views' | 'likes' | 'comments';
type GraphType = 'total' | 'daily' | 'relative';

interface VideoMetadata {
  id: string;
  title: string;
  publishDate: string;
  type: string;
  modelTopic: string;
  wikipediaTopic: string;
  videoLength: string;
  firstTrackedDate: string;
}

interface VideoPerformance {
  totalViews: number;
  totalLikes: number;
  totalComments: number;
  viewsChange: number | null;
  likesChange: number | null;
  commentsChange: number | null;
  conversationRate: number;
  likeRate: number;
  rankAll: number;
  rankType: number;
  lastUpdated: string;
}

interface PerformanceHistoryItem {
  date: string;
  views: number;
  likes: number;
  comments: number;
  daysLive: number;
}

const navItems = [
  { title: 'Channels', href: '/channels' },
  { title: 'Channel', href: '/channels/channel' },
  { title: 'Top 5', href: '/channels/top-five' },
  { title: 'Video', href: '/channels/video' },
  { title: 'Networks', href: '/channels/networks/' },
  { title: 'Timeline', href: '/channels/timeline' },
  { title: 'Performance', href: '/channels/performance' },
  { title: 'Data', href: '/channels/data' },
  { title: 'Notes', href: 'https://brick-river-8a5.notion.site/Channels-Video-11f6777fc955803c8f47fe7a3a977b46', isExternal: true },
];

export function Video() {
  const [selectedChannel, setSelectedChannel] = useState<string | null>(null);
  const [selectedContentType, setSelectedContentType] = useState<string>('All');
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null);
  const [metadata, setMetadata] = useState<VideoMetadata | null>(null);
  const [performance, setPerformance] = useState<VideoPerformance | null>(null);
  const [dailyData, setDailyData] = useState<any>(null);
  const [totalData, setTotalData] = useState<any>(null);
  const [relativeData, setRelativeData] = useState<any>(null);
  const [selectedMetric, setSelectedMetric] = useState<MetricKey>('views');
  const [selectedGraphType, setSelectedGraphType] = useState<GraphType>('total');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();

  useEffect(() => {
    if (selectedChannel && selectedVideo) {
      fetchVideoData();
    }
  }, [selectedChannel, selectedVideo]);

  const fetchVideoData = async () => {
    if (!selectedChannel || !selectedVideo) return;

    setLoading(true);
    setError(null);

    try {
      const [
        metadataResponse, 
        performanceResponse, 
        dailyDataResponse, 
        totalDataResponse,
        relativeDataResponse
      ] = await Promise.all([
        getChannelVideosPage(selectedChannel, selectedVideo),
        getChannelVideosPagePerformance(selectedChannel, selectedVideo),
        getVideoPerformanceDailyData(selectedChannel, selectedVideo),
        getVideoPerformanceTotalData(selectedChannel, selectedVideo),
        getVideoPerformanceRelativeData(selectedChannel, selectedVideo)
      ]);

      setMetadata(metadataResponse.data);
      setPerformance(performanceResponse.data);
      setDailyData(dailyDataResponse.data);
      setTotalData(totalDataResponse.data);
      setRelativeData(relativeDataResponse.data);
    } catch (err) {
      setError('Failed to fetch video data. Please try again.');
      console.error('Error fetching video data:', err);
    } finally {
      setLoading(false);
    }
  };

  const renderChart = () => {
    switch (selectedGraphType) {
      case 'total':
        return (
          <TotalChart
            chartData={totalData.chartData}
            metric="views"
            publishDate={totalData.publishDate}
            firstTrackedDate={totalData.firstTrackedDate}
            lastTrackedDate={totalData.lastTrackedDate}
            updatedAtDate={totalData.updatedAtDate}
          />
        );
      case 'daily':
        return (
          <DailyGraph
            data={dailyData.performanceData}
            metric={selectedMetric}
            publishDate={dailyData.publishDate}
            firstTrackedDate={dailyData.firstTrackedDate}
            lastUpdatedDate={dailyData.lastUpdatedDate}
            gaps={dailyData.gaps}
            isDay2Scenario={dailyData.isDay2Scenario}
          />
        );
      case 'relative':
        return (
          <VideoRelativeChart
            data={relativeData}
            publishDate={relativeData?.publishDate || ''}
          />
        );
      default:
        return <Text>Invalid chart type selected.</Text>;
    }
  };

  return (
    <Stack gap="md">
      {/* ... (navigation and other components remain the same) */}
      <Paper p="md" radius="md" withBorder>
        <Group gap="apart" mb="md">
          <ChannelPicker value={selectedChannel} onChange={setSelectedChannel} />
          <ContentTypePicker
            channelId={selectedChannel}
            value={selectedContentType}
            onChange={(value) => setSelectedContentType(value || 'All')}
          />
          <VideoPicker
            channelId={selectedChannel}
            contentType={selectedContentType}
            onChange={setSelectedVideo}
          />
        </Group>
        {loading ? (
          <SkeletonLoader count={3} height={200} />
        ) : error ? (
          <Text color="red" mt="md">{error}</Text>
        ) : metadata && performance ? (
          <Grid mt="md">
            <Grid.Col span={4}>
              <PerformanceCard
                performance={performance}
                contentType={selectedContentType}
                lastUpdated={performance.lastUpdated}
                isOldWithLowViews={dailyData?.gaps.some((gap: any) => gap.type === 'post-tracking')}
              />
            </Grid.Col>
            <Grid.Col span={8}>
              <VideoInfoCard
                metadata={metadata}
                firstTrackedDate={metadata.firstTrackedDate}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <Stack gap="md">
                <Group gap="apart">
                  <GraphTypePicker
                    value={selectedGraphType}
                    onChange={setSelectedGraphType}
                  />
                  {selectedGraphType === 'daily' && (
                    <MetricPicker
                      value={selectedMetric}
                      onChange={setSelectedMetric}
                    />
                  )}
                </Group>
                {renderChart()}
              </Stack>
            </Grid.Col>
          </Grid>
        ) : null}
      </Paper>
    </Stack>
  );
}