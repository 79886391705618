import React from 'react';
import { Carousel } from '@mantine/carousel';
import { Card, Image, Text, Group, Stack, Box, Badge, Avatar } from '@mantine/core';
import { formatDateUK } from '../../../utils/dateUtils';

interface TrendingVideo {
  videoId: string;
  title: string;
  rank: number;
  views: number;
  comments: number;
  conversationRate: number;
  publishDate: string;
  channelName: string;
  channelIconUrl: string;
}

interface TrendingVideoCarouselProps {
  videos: TrendingVideo[];
  region: 'US' | 'GB';
}

const getRankColor = (rank: number): string => {
  const red = Math.round(255 * (rank - 1) / 49);
  const blue = Math.round(255 * (50 - rank) / 49);
  return `rgb(${red}, 0, ${blue})`;
};

const removeThumbnailLetterboxing = (url: string): string => {
  const height = (360 - (480 / (1280 / 720))) / 2;
  return `${url}#crop=45:${Math.round(height)}:0:0`;
};

export function TrendingVideoCarousel({ videos, region }: TrendingVideoCarouselProps) {
  return (
    <Card withBorder radius="md" p="md">
      <Text fz="md" tt="uppercase" fw={700} ta="center" mb="xs">
        Trending in {region === 'US' ? '🇺🇸' : '🇬🇧'}
      </Text>
      <Carousel
        slideSize="100%"
        slideGap="sm"
        align="center"
        slidesToScroll={1}
        controlSize={20}
        styles={{
          control: {
            '&[data-inactive]': {
              opacity: 0,
              cursor: 'default',
            },
          },
        }}
      >
        {videos.map((video) => (
          <Carousel.Slide key={video.videoId}>
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Card withBorder radius="md" p="xl">
              <Card.Section>
                <Box
                  style={{
                    width: 480,
                    height: 270,
                    overflow: 'hidden',
                    borderRadius: 8,
                    boxShadow: '1px 0 1px rgba(0,0,0,0.1)',
                    position: 'relative',
                  }}
                >
                  <Image
                    src={removeThumbnailLetterboxing(`https://img.youtube.com/vi/${video.videoId}/hqdefault.jpg`)}
                    alt={video.title}
                    width={480}
                    height={270}
                    fit="cover"
                  />
                </Box>
              </Card.Section>
              <Stack gap="xs" mt="md">
              <Group wrap="nowrap" align="flex-start" mt="md">
                <Badge 
                  size="lg"
                  radius="sm"
                  style={{
                    backgroundColor: getRankColor(video.rank),
                    color: 'white',
                    minWidth: '40px',
                    fontWeight: 'bold'
                  }}
                >
                  #{video.rank}
                </Badge>
                <Text fw={500} fz="lg" fs="italic" lineClamp={2} style={{ flex: 1 }}>
                  {video.title}
                </Text>
              </Group>
                <Group gap="xs">
                  <Avatar src={video.channelIconUrl} size="sm" radius="xl" />
                  <Text fz="sm" fw={500}>
                    {video.channelName}
                  </Text>
                </Group>
                <Text fz="sm" fw={500}>
                  Published: {formatDateUK(new Date(video.publishDate))}
                </Text>
                <Text fz="sm" fw={500}>Views: {video.views.toLocaleString()}</Text>
                <Text fz="sm" fw={500}>Comments: {video.comments.toLocaleString()} | Conversation Rate: {(video.conversationRate).toFixed(2)}</Text>
              </Stack>
            </Card>
            </Box>
          </Carousel.Slide>
        ))}
      </Carousel>
    </Card>
  );
}
