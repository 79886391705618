import React, { useState, useEffect } from 'react';
import { Paper, Group, Select, Grid, Text, Stack } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import { IconChevronRight, IconExternalLink } from '@tabler/icons-react';
import { ScatterChart, XAxis, YAxis, ZAxis, Tooltip, Scatter, ResponsiveContainer, Legend } from 'recharts';
import { SkeletonLoader } from '../../../core/SkeletonLoader';
import { ChannelPicker } from '../../components/ChannelPicker';
import { getChannelPerformanceData } from '../../../../utils/api';

const navItems = [
  { title: 'Channels', href: '/channels' },
  { title: 'Summaries', href: '/channels/summaries' },
  { title: 'Channel', href: '/channels/summaries/channel' },
  { title: 'Top 5', href: '/channels/summaries/top-five' },
  { title: 'Video', href: '/channels/summaries/videos' },
  { title: '2D', href: '/channels/summaries/two-dims' },
  { title: '3D', href: '/channels/summaries/three-dims' },
  { title: 'Data', href: '/channels/summaries/data' },
  { title: 'Tracked Channels', href: 'https://brick-river-8a5.notion.site/Tracked-Channels-1126777fc95580f39bc6cc5d7b7dbcc9', isExternal: true },
  { title: 'Notes', href: 'https://brick-river-8a5.notion.site/Channel-Summaries-Notes-1086777fc95580e699e6efaeddc27fe2', isExternal: true },
];

// Utility function to check if the current path matches or starts with the given href
const isActive = (currentPath: string, href: string) => {
  if (href === '/channels' && currentPath.startsWith('/channels')) return true;
  if (href === '/channels/summaries' && currentPath.startsWith('/channels/summaries')) return true;
  return currentPath === href;
};

interface PerformanceDataItem {
  videoId: string;
  title: string;
  views: number;
  comments: number;
  conversationRate: number;
  contentType: 'Video' | 'Short' | 'Podcast';
}

const contentTypeColors = {
  Video: '#FF0000',  // Red
  Short: '#0000FF',  // Blue
  Podcast: '#800080' // Purple (as a neutral option)
};

const yAxisOptions = [
  { value: 'comments', label: 'Comments' },
  { value: 'conversationRate', label: 'Conversation Rate' },
];

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length && payload[0].payload) {
    const data = payload[0].payload;
    return (
      <div style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}>
        <p>{`Title: ${data.title || 'N/A'}`}</p>
        <p>{`Views: ${data.views ? data.views.toLocaleString() : 'N/A'}`}</p>
        <p>{`${payload[0].name}: ${data[payload[0].name] ? data[payload[0].name].toLocaleString() : 'N/A'}`}</p>
        <p>{`Content Type: ${data.contentType || 'N/A'}`}</p>
      </div>
    );
  }
  return null;
};

export function ChannelPerformance2DScatter() {
  const [selectedChannel, setSelectedChannel] = useState<string | null>(null);
  const [selectedYAxis, setSelectedYAxis] = useState<string>('comments');
  const [performanceData, setPerformanceData] = useState<PerformanceDataItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();

  useEffect(() => {
    if (selectedChannel) {
      setLoading(true);
      setError(null);
      
      getChannelPerformanceData(selectedChannel)
        .then(response => {
          setPerformanceData(response.data);
          setLoading(false);
        })
        .catch(err => {
          setError("Error fetching data. Please try again.");
          setLoading(false);
        });
    }
  }, [selectedChannel]);

  const renderPerformanceScatter = () => (
    <ResponsiveContainer width="100%" height={500}>
      <ScatterChart margin={{ top: 20, right: 20, bottom: 60, left: 40 }}>
        <XAxis
          type="number"
          dataKey="views"
          name="Views"
          label={{ value: 'Views', position: 'bottom', offset: 0 }}
        />
        <YAxis
          type="number"
          dataKey={selectedYAxis}
          name={selectedYAxis === 'comments' ? 'Comments' : 'Conversation Rate'}
          label={{ value: selectedYAxis === 'comments' ? 'Comments' : 'Conversation Rate', angle: -90, position: 'insideLeft' }}
        />
        <Tooltip content={<CustomTooltip />} />
        <Legend layout="horizontal" verticalAlign="bottom" align="center" wrapperStyle={{ paddingTop: 20 }} />
        {Object.entries(contentTypeColors).map(([contentType, color]) => (
          <Scatter
            key={contentType}
            name={contentType}
            data={performanceData.filter(item => item.contentType === contentType)}
            fill={color}
          />
        ))}
      </ScatterChart>
    </ResponsiveContainer>
  );

  return (
    <Stack gap="xs">
      <Group gap={5}>
      {navItems.map((item, index) => (
          <React.Fragment key={item.title}>
            {index > 0 && index < 3 && <IconChevronRight size={14} color='#868e96'/>}
            {index > 1 && <Text size="sm" color="dimmed">|</Text>}
            {item.isExternal ? (
              <Text
                component="a"
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
                size="sm"
                c="#0982eb"
                style={{ display: 'flex', alignItems: 'center' }}
            >
              {item.title}
              <IconExternalLink size={14} style={{ marginLeft: 5 }} />
            </Text>
          ) : (
            <Text
              component={Link}
              to={item.href}
              size="sm"
              fw={index >= 2 && location.pathname === item.href ? 500 : 'normal'}
              td={index >= 2 && location.pathname === item.href ? 'underline' : 'none'}
              c={index < 2 ? 'dimmed' : '#0982eb'}
            >
              {item.title}
            </Text>
          )}
        </React.Fragment>
      ))}
      </Group>
      <Paper p="xs" shadow="xs" radius="sm">
        <Stack gap="xs">
          <Grid gutter="xs">
            <Grid.Col span="content">
              <ChannelPicker value={selectedChannel} onChange={setSelectedChannel} />
            </Grid.Col>
            <Grid.Col span="content">
              <Select
                data={yAxisOptions}
                value={selectedYAxis}
                onChange={(value) => setSelectedYAxis(value || 'comments')}
                placeholder="Select Y-axis metric"
              />
            </Grid.Col>
          </Grid>
          {loading ? (
            <SkeletonLoader count={1} height={500} radius="sm" />
          ) : error ? (
            <Text color="red">{error}</Text>
          ) : (
            renderPerformanceScatter()
          )}
        </Stack>
      </Paper>
    </Stack>
  );
}