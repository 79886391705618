import React from 'react';
import BaseChart from './ChannelBaseChart';

interface LibrarySizeAllChartProps {
  data: Array<{
    date: string;
    totalVideos: number;
  }>;
  startDate: string;
  firstTrackedDate: string | null;
  lastTrackedDate: string;
}

const LibrarySizeAllChart: React.FC<LibrarySizeAllChartProps> = ({
  data,
  startDate,
  firstTrackedDate,
  lastTrackedDate
}) => {
  const areas = [
    { dataKey: 'totalVideos', stroke: '#8884d8', fill: '#8884d8' }
  ];

  // If firstTrackedDate is null, use startDate as a fallback
  const effectiveFirstTrackedDate = firstTrackedDate || startDate;

  return (
    <BaseChart
      data={data}
      xDataKey="date"
      areas={areas}
      startDate={startDate}
      firstTrackedDate={effectiveFirstTrackedDate}
      lastTrackedDate={lastTrackedDate}
    />
  );
};

export default LibrarySizeAllChart;
