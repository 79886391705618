import React, { useState, useEffect } from 'react';
import { Paper, Group, Select, Grid, Text, Stack } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import { IconChevronRight, IconExternalLink } from '@tabler/icons-react';
import Plot from 'react-plotly.js';
import { SkeletonLoader } from '../../../core/SkeletonLoader';
import { ChannelPicker } from '../../components/ChannelPicker';
import { getChannelPerformance3DData } from '../../../../utils/api';

const navItems = [
  { title: 'Channels', href: '/channels' },
  { title: 'Summaries', href: '/channels/summaries' },
  { title: 'Channel', href: '/channels/summaries/channel' },
  { title: 'Top 5', href: '/channels/summaries/top-five' },
  { title: 'Video', href: '/channels/summaries/videos' },
  { title: '2D', href: '/channels/summaries/two-dims' },
  { title: '3D', href: '/channels/summaries/three-dims' },
  { title: 'Data', href: '/channels/summaries/data' },
  { title: 'Tracked Channels', href: 'https://brick-river-8a5.notion.site/Tracked-Channels-1126777fc95580f39bc6cc5d7b7dbcc9', isExternal: true },
  { title: 'Notes', href: 'https://brick-river-8a5.notion.site/Channel-Summaries-Notes-1086777fc95580e699e6efaeddc27fe2', isExternal: true },
];

// Utility function to check if the current path matches or starts with the given href
const isActive = (currentPath: string, href: string) => {
  if (href === '/channels' && currentPath.startsWith('/channels')) return true;
  if (href === '/channels/summaries' && currentPath.startsWith('/channels/summaries')) return true;
  return currentPath === href;
};

interface PerformanceDataItem {
  videoId: string;
  title: string;
  type: string;
  wikipediaTopic: string;
  modelTopic: string;
  views: number;
  comments: number;
  conversationRate: number;
}

const yAxisOptions = [
  { value: 'wikipediaTopic', label: 'Wikipedia Topic' },
  { value: 'modelTopic', label: 'Model Topic' },
];

const zAxisOptions = [
  { value: 'views', label: 'Views' },
  { value: 'comments', label: 'Comments' },
  { value: 'conversationRate', label: 'Conversation Rate' },
];

export function ChannelPerformance3DScatter() {
  const [selectedChannel, setSelectedChannel] = useState<string | null>(null);
  const [selectedYAxis, setSelectedYAxis] = useState<string>('modelTopic');
  const [selectedZAxis, setSelectedZAxis] = useState<string>('views');
  const [performanceData, setPerformanceData] = useState<PerformanceDataItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();

  useEffect(() => {
    if (selectedChannel) {
      setLoading(true);
      setError(null);
      
      getChannelPerformance3DData(selectedChannel)
        .then(response => {
          setPerformanceData(response.data);
          setLoading(false);
        })
        .catch(err => {
          setError("Error fetching data. Please try again.");
          setLoading(false);
        });
    }
  }, [selectedChannel]);

  const renderPerformance3DScatter = () => {
    const xValues = performanceData.map(item => item.type);
    const yValues = performanceData.map(item => item[selectedYAxis as keyof PerformanceDataItem]);
    const zValues = performanceData.map(item => item[selectedZAxis as keyof PerformanceDataItem]);

    return (
      <Plot
        data={[
          {
            x: xValues,
            y: yValues,
            z: zValues,
            mode: 'markers',
            type: 'scatter3d',
            marker: {
              size: 5,
              color: zValues,
              colorscale: 'Bluered',
              opacity: 0.8,
            },
            text: performanceData.map(item => `Title: ${item.title}<br>Type: ${item.type}<br>${selectedYAxis}: ${item[selectedYAxis as keyof PerformanceDataItem]}<br>${selectedZAxis}: ${item[selectedZAxis as keyof PerformanceDataItem]}`),
            hoverinfo: 'text',
          },
        ]}
        layout={{
          // width: '100%',
          height: 600,
          margin: { l: 0, r: 0, b: 0, t: 0 },
          scene: {
            xaxis: { title: 'Content Type' },
            yaxis: { title: selectedYAxis === 'wikipediaTopic' ? 'YouTubes Topic' : 'Model Topic' },
            zaxis: { title: zAxisOptions.find(option => option.value === selectedZAxis)?.label },
          },
        }}
      />
    );
  };

  return (
    <Stack gap="xs">
      <Group gap={5}>
      {navItems.map((item, index) => (
          <React.Fragment key={item.title}>
            {index > 0 && index < 3 && <IconChevronRight size={14} color='#868e96'/>}
            {index > 1 && <Text size="sm" color="dimmed">|</Text>}
            {item.isExternal ? (
              <Text
                component="a"
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
                size="sm"
                c="#0982eb"
                style={{ display: 'flex', alignItems: 'center' }}
            >
              {item.title}
              <IconExternalLink size={14} style={{ marginLeft: 5 }} />
            </Text>
          ) : (
            <Text
              component={Link}
              to={item.href}
              size="sm"
              fw={index >= 2 && location.pathname === item.href ? 500 : 'normal'}
              td={index >= 2 && location.pathname === item.href ? 'underline' : 'none'}
              c={index < 2 ? 'dimmed' : '#0982eb'}
            >
              {item.title}
            </Text>
          )}
        </React.Fragment>
      ))}
      </Group>
      <Paper p="xs" shadow="xs" radius="sm">
        <Stack gap="xs">
          <Grid gutter="xs">
            <Grid.Col span="content">
              <ChannelPicker value={selectedChannel} onChange={setSelectedChannel} />
            </Grid.Col>
            <Grid.Col span="content">
              <Select
                data={yAxisOptions}
                value={selectedYAxis}
                onChange={(value) => setSelectedYAxis(value || 'modelTopic')}
                placeholder="Select Y-axis"
              />
            </Grid.Col>
            <Grid.Col span="content">
              <Select
                data={zAxisOptions}
                value={selectedZAxis}
                onChange={(value) => setSelectedZAxis(value || 'views')}
                placeholder="Select Z-axis"
              />
            </Grid.Col>
          </Grid>
          {loading ? (
            <SkeletonLoader count={1} height={600} radius="sm" />
          ) : error ? (
            <Text color="red">{error}</Text>
          ) : (
            renderPerformance3DScatter()
          )}
        </Stack>
      </Paper>
    </Stack>
  );
}