import React from 'react';
import { ResponsiveContainer, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { formatNumber, formatDate } from '../../../utils/formatter';

interface BaseChartProps {
  data: any[];
  xDataKey: string;
  areas: Array<{
    dataKey: string;
    stroke: string;
    fill: string;
  }>;
  startDate: string;
  firstTrackedDate: string | null;
  lastTrackedDate: string;
}

const BaseChart: React.FC<BaseChartProps> = ({
  data,
  xDataKey,
  areas,
  startDate,
  firstTrackedDate,
  lastTrackedDate
}) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <AreaChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis 
          dataKey={xDataKey} 
          tick={<CustomizedAxisTick />}
          ticks={[startDate, ...(firstTrackedDate ? [firstTrackedDate] : []), lastTrackedDate]}
        />
        <YAxis tickFormatter={(value) => formatNumber(value)} />
        <Tooltip content={<CustomTooltip />} />
        {areas.map((area, index) => (
          <Area
            key={index}
            type="monotone"
            dataKey={area.dataKey}
            stroke={area.stroke}
            fill={area.fill}
            fillOpacity={0.3}
          />
        ))}
      </AreaChart>
    </ResponsiveContainer>
  );
};

const CustomizedAxisTick: React.FC<any> = ({ x, y, payload }) => (
  <g transform={`translate(${x},${y})`}>
    <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)" fontSize={12}>
      {formatDate(payload.value)}
    </text>
  </g>
);

const CustomTooltip: React.FC<any> = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}>
        <p>{`Date: ${formatDate(label)}`}</p>
        {payload.map((entry: any, index: number) => (
          <p key={index} style={{ color: entry.color }}>
            {`${entry.name}: ${formatNumber(entry.value)}`}
          </p>
        ))}
      </div>
    );
  }
  return null;
};

export default BaseChart;
