import React from 'react';
import { Container, Title, Text, List, Blockquote, Image, Group, Stack, Divider } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import { IconChevronRight } from '@tabler/icons-react';

interface ReleaseNote {
  version: string;
  date: string;
  changes: string[];
  newFeatures: string[];
  screenshot?: string;
  quote?: string;
}

interface ReleaseNotesProps {
  notes: ReleaseNote[];
}

const navItems = [
  { title: 'Docs', href: '/docs' },
  { title: 'Releases', href: '/docs/releases' },
  { title: 'New! v1.0.0', href: '/docs/releases/new-one' },
  { title: 'Build Process', href: '/docs/releases/build-process' },
];

export function BuildNotes({ notes }: ReleaseNotesProps) {
  const location = useLocation();

  return (
    <Container size="lg">
      <Stack gap="md">
        {/* Navigation */}
        <Group gap={5}>
          {navItems.map((item, index) => (
            <React.Fragment key={item.title}>
              {index > 0 && <IconChevronRight size={14} color='#868e96' />}
              <Text
                component={Link}
                to={item.href}
                size="sm"
                fw={location.pathname === item.href ? 500 : 'normal'}
                td={location.pathname === item.href ? 'underline' : 'none'}
                c={index === navItems.length - 1 ? 'dimmed' : '#0982eb'}
              >
                {item.title}
              </Text>
            </React.Fragment>
          ))}
        </Group>

        <Title order={1}>Release Notes</Title>

        {notes.map((note, index) => (
          <Stack key={note.version} gap="sm">
            <Title order={2}>{`Version ${note.version} (${note.date})`}</Title>

            {note.screenshot && (
              <Image
                src={note.screenshot}
                alt={`Screenshot for version ${note.version}`}
              />
            )}

            {note.changes.length > 0 && (
              <>
                <Text fw={700}>Changes:</Text>
                <List>
                  {note.changes.map((change, idx) => (
                    <List.Item key={idx}>{change}</List.Item>
                  ))}
                </List>
              </>
            )}

            {note.newFeatures.length > 0 && (
              <>
                <Text fw={700}>New Features:</Text>
                <List>
                  {note.newFeatures.map((feature, idx) => (
                    <List.Item key={idx}>{feature}</List.Item>
                  ))}
                </List>
              </>
            )}

            {note.quote && (
              <Blockquote cite="- Development Team">
                {note.quote}
              </Blockquote>
            )}

            {index < notes.length - 1 && <Divider my="lg" />}
          </Stack>
        ))}
      </Stack>
    </Container>
  );
}

const releaseNotes: ReleaseNote[] = [
  {
    version: "0.0.73",
    date: "September 12, 2024",
    changes: [
      "Build out swarm plot, box plot and data grids for topics",
      "Topics timeline is now a bar chart that allows you to select which topics to view for a channel",
      "Logout works, not that you want to use that... And, fixed an annoying scrolling issue",
    ],
    newFeatures: [
      "You can see the last row of tables!",
      "Lots of new charts",
    ],
  },
  {
    version: "0.0.72",
    date: "September 11, 2024",
    changes: [
      "Build out treemaps and correct them",
      "Custom date picker for Trending Summary",
    ],
    newFeatures: [
      "Improved Trending Summary picker",
      "Treemaps",
    ],
  },
  {
    version: "0.0.71",
    date: "September 10, 2024",
    changes: [
      "Refinements across channel pages",
      "The date you select is the date returned from the API",
      "Pages, navs and codebase tidied up",
    ],
    newFeatures: [
      "Badges on Trending Summary",
      "Performance Scatter graph broken out from Summary on Trending",
    ],
  },
  {
    version: "0.0.70",
    date: "September 09, 2024",
    changes: [
      "Further API endpoints and integrations for channels pages",
      "Channel picker component and API data integrated with Treemaps",
      "Trending Network forces refactored, Trending page structure clean up",
    ],
    newFeatures: [
      "API-connected channel treemaps",
      "Better composed Trending Network",
    ],
  },
  {
    version: "0.0.69",
    date: "September 08, 2024",
    changes: [
      "API created and integrated for channels",
      "Channel picker component with API integration and channel icons for channels which are fully tracked",
      "Channel calendar page connected to API with channel picker",
    ],
    newFeatures: [
      "API-connected channel picker",
      "Channel calendar page",
    ],
  },
  {
    version: "0.0.68",
    date: "September 07, 2024",
    changes: [
      "API integrated for Trending Networks (all days now available)",
      "Trending Networks visualisation with sub-graph highlighting",
      "Updated Trending Networks backend functions",
    ],
    newFeatures: [
      "API-connected Trending Networks",
    ],
  },
  {
    version: "0.0.67",
    date: "September 06, 2024",
    changes: [
      "API and cache integrated for Trending Summary Summary page and Grid (all days now available)",
      "Sortable AGGrid for Trending Summary Data Grid",
      "Scrollable thumbnail carousels for the Trending Summary page (API connected)",
    ],
    newFeatures: [
      "API connections",
      "More data",
      "Scrollable carouselss",
      "A working grid",
    ],
  },
  {
    version: "0.0.62",
    date: "September 01, 2024",
    changes: [
      "Networks replatformed to force-react-graph",
      "Uses toy data, hover states and node/link highlights",
      "Trending networks works on 10th August for GB; Channels for Dwarkesh Patel across types",
    ],
    newFeatures: [
      "react-force-graphs networks for Trending & Channel networks",
    ],
  },
  {
    version: "0.0.61",
    date: "August 31, 2024",
    changes: [
      "Refining Trending Logic",
    ],
    newFeatures: [
      "Trending layout a bit better, logic still lacking ",
    ],
  },
  {
    version: "0.0.60",
    date: "August 30, 2024",
    changes: [
      "Trending Summary visualisation and functionality",
      "Pretty aggressive use of hover states which needs some fixing",
      "Uses recharts and defined logic (which isn't quite there) plus fake data",
    ],
    newFeatures: [
      "Trending Summary which needs a couple of updates but is broadly functional",
    ],
  },
  {
    version: "0.0.59",
    date: "August 29, 2024",
    changes: [
      "WebCoLa / D3 visualisation for channel networks running quickly, with tooltips. No colors drag or zoom. Yet",
      "React Plotly Treemap for Topics up and running and working better than D3",
    ],
    newFeatures: [
      "The treemap for Topics works as expected with Plotly",
      "A new fast network graph",
    ],
  },
  {
    version: "0.0.58",
    date: "August 28, 2024",
    changes: [
      "Good thing we're using extra decimal places for versions",
      "Semi functional D3 treemap, not quite working perfectly, but fits mock data",
    ],
    newFeatures: [
      "Treemap is now running for 'Channel 1' and 'Channel 2'",
      "If you click through, click 'Apply' again to go back to the home state",
    ],
  },
  {
    version: "0.0.57",
    date: "August 27, 2024",
    changes: [
      "Trending Network visualisation using model data (real data but not API-based)",
      "Hover states on Trending Network graph, some drab colours, and directional arrows",
      "It works at 10th August, GB, takes a couple of seconds to render properly",
    ],
    newFeatures: [
      "An actual visualisation which uses actual data and will have few changes before live",
    ],
  },
  {
    version: "0.0.56",
    date: "August 26, 2024",
    changes: [
      "Aligned header items and replaced the avatar with a dog",
      "Added sub page logic to navbar with active states, etc",
      "Navbar opens on any click, not just menu",
      "Improved and finalised on-page navigation",
    ],
    newFeatures: [
      "Aligned header icons",
      "Working external links",
      "Final navigation in navbar and on page",
    ],
  },
  {
    version: "0.0.55",
    date: "August 25, 2024",
    changes: [
      "Finalised PageSets layouts",
      "Implemented new on-page nav presentation & logic",
      "Implemented all PageSets and pages required (all duplicates)",
    ],
    newFeatures: [
      "Nicer-looking PageSets & pages",
      "Nicer-looking nav",
      "Lots of new pages with the same data"
    ],
  },
  {
    version: "0.0.50",
    date: "August 24, 2024",
    changes: [
      "Developed PageSet layout with toy data D3 force-directed tree and AG-Grid",
      "Refined core layout and nav bar",
      "Fixed login page and reinstated the menu",
    ],
    newFeatures: [
      "A PageSet under Networks/Trending with toy graph and grid",
    ],
  },
  {
    version: "0.0.40",
    date: "August 23, 2024",
    changes: [
      "Upgraded some UI bits etc",
      "Undid a lot of work, then did it back again",
      "Site now runs on the latest version of the UI components",
    ],
    newFeatures: [
      "The same as before, but no blinking!",
    ],
  },
  {
    version: "0.0.30",
    date: "August 22, 2024",
    changes: [
      "Refined navbar design and logic",
      "The header menu contains external links, the navbar contains internal links",
      "Navbar has active states, toggles and indents",
    ],
    newFeatures: [
      "A slightly blinky navbar with working links",
    ],
  },
  {
    version: "0.0.20",
    date: "August 21, 2024",
    changes: [
      "Created Core Layout with navbar, some icons, header with user menu, footer",
      "The design needs some tweaks",
      "Text-only layouts for text-only pages",
    ],
    newFeatures: [
      "Core Layout",
      "Text Only Pages",
    ],
  },
  {
    version: "0.0.10",
    date: "August 20, 2024",
    changes: [
      "Login page",
      "Working login",
      "A graph visualisation on the login page",
    ],
    newFeatures: [
      "Login Page",
    ],
  },
];

export function BuildNotesPage() {
  return <BuildNotes notes={releaseNotes} />;
}