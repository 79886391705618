import React, { useState, useEffect } from 'react';
import { Group, Text, Stack, Select, Paper } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import { IconChevronRight } from '@tabler/icons-react';
import { SkeletonLoader } from '../../../core/SkeletonLoader';
import { ChannelPicker } from '../../components/ChannelPicker';
import { TopicsTreemap } from '../../components/TopicsTreemap';
import { TypesTreemap } from '../../components/TypesTreemap';
import { getChannelTopicsTreemapData, getChannelTypesTreemapData, getChannelWikipediaTopicsTreemapData } from '../../../../utils/api';

const navItems = [
  { title: 'Channels', href: '/channels' },
  { title: 'Treemaps', href: '/channels/treemaps' },
];

export function TreemapPage() {
  const [selectedChannel, setSelectedChannel] = useState<string | null>(null);
  const [selectedTreemapType, setSelectedTreemapType] = useState<string | null>('model-topics');
  const [loading, setLoading] = useState(false);
  const [treeData, setTreeData] = useState<any>(null);
  const [channelName, setChannelName] = useState<string>('');
  const [channelViews, setChannelViews] = useState<number>(0);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();

  useEffect(() => {
    if (selectedChannel && selectedTreemapType) {
      setLoading(true);
      setError(null);
      let fetchData;
      switch (selectedTreemapType) {
        case 'model-topics':
          fetchData = getChannelTopicsTreemapData;
          break;
        case 'youtube-topics':
          fetchData = getChannelWikipediaTopicsTreemapData;
          break;
        case 'types':
          fetchData = getChannelTypesTreemapData;
          break;
        default:
          setError('Invalid treemap type selected');
          setLoading(false);
          return;
      }

      fetchData(selectedChannel)
        .then(response => {
          setTreeData(response.data);
          setChannelName(response.channelName);
          setChannelViews(response.channelViews);
          setLoading(false);
        })
        .catch(err => {
          console.error('Error fetching treemap data:', err);
          setError('Failed to fetch treemap data');
          setLoading(false);
        });
    }
  }, [selectedChannel, selectedTreemapType]);

  const renderNavigation = () => (
    <Group gap={5}>
      {navItems.map((item, index) => (
        <React.Fragment key={item.title}>
          {index > 0 && <IconChevronRight size={14} color='#868e96'/>}
          <Text
            component={Link}
            to={item.href}
            size="sm"
            fw={location.pathname === item.href ? 500 : 'normal'}
            td={location.pathname === item.href ? 'underline' : 'none'}
            c={index === 0 ? 'dimmed' : '#0982eb'}
          >
            {item.title}
          </Text>
        </React.Fragment>
      ))}
    </Group>
  );

  const renderTreemap = () => {
    if (loading) {
      return <SkeletonLoader count={1} height={600} radius="sm" />;
    }
    if (error) {
      return <Text color="red">{error}</Text>;
    }
    if (!treeData) {
      return null;
    }

    switch (selectedTreemapType) {
      case 'model-topics':
      case 'youtube-topics':
        return <TopicsTreemap data={treeData} channelName={channelName} channelViews={channelViews}/>;
      case 'types':
        return <TypesTreemap data={treeData} channelName={channelName} channelViews={channelViews}/>;
      default:
        return <Text>Please select a valid treemap type</Text>;
    }
  };

  return (
    <Stack gap="md">
      {renderNavigation()}
      <Paper p="md" withBorder>
        <Stack gap="md">
          <Group>
            <ChannelPicker value={selectedChannel} onChange={setSelectedChannel} />
            <Select
              placeholder="Select treemap type"
              value={selectedTreemapType}
              onChange={setSelectedTreemapType}
              data={[
                { value: 'types', label: 'Content Types' },
                { value: 'model-topics', label: 'Model Topics' },
                { value: 'youtube-topics', label: 'YouTube Topics' },
              ]}
            />
          </Group>
          {renderTreemap()}
        </Stack>
      </Paper>
    </Stack>
  );
}